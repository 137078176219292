import React from "react";
import "./AdminDetail.css";
import MainTemplate from "../../../components/MainTemplate";
import User from "../../../assets/images/profile.png"

function AdminDetail() {

	return (
		<MainTemplate categoryName="Account" categoryList={["Account", "Admin", "Ram Nath Singh"]}>
			<div className="row mb-4 rounded bg-white homepageModifierPadding mb-4">
				<div className="col-lg-5 mb-4">
					<div className="w-50 border rounded mb-3">
						<img className="w-100 rounded" src={User} />
					</div>
					<div className="form-group pb-2">
						<label className="font-weight-bold">Name</label>
						<p>Ram Nath Singh</p>
					</div>
					<div className="form-group pb-2">
						<label className="font-weight-bold">Phone number</label>
						<a href="tel:123-456-7890"><p>123-456-7890</p></a>
					</div>
					<div className="form-group pb-2">
						<label className="font-weight-bold">Email</label>
						<a href="mailto:abc@example.com"><p>abc@example.com</p></a>
					</div>
					<div className="form-group pb-2">
						<label className="font-weight-bold">Password</label>
						<p>Password11@@</p>
					</div>
					<div className="form-group pb-2 d-flex">
						<label className="font-weight-bold me-5">Account Status</label>
						<label class="switch ">
							<input class="switch-input" type="checkbox" />
							<span class="switch-label"></span> 
							<span class="switch-handle"></span> 
						</label>
					</div>
				</div>
				<div className="col-lg-5 mb-4">
					<div className="py-2">
						<label className="font-weight-bold">Module Access</label>
						<div className="bg-light rounded py-1 mt-2 ">
							<div class="form-check mx-4 my-3 d-flex align-items-center">
								<input class="form-check-input me-4 dark-checkbox" type="checkbox" value="" id="flexCheckDefault"/>
								<label class="form-check-label" for="flexCheckDefault">
									Categories
								</label>
							</div>
							<div class="form-check mx-4 my-3 d-flex align-items-center">
								<input class="form-check-input me-4 dark-checkbox" type="checkbox" value="" id="flexCheckChecked"/>
								<label class="form-check-label" for="flexCheckChecked">
									Orders
								</label>
							</div>
							<div class="form-check mx-4 my-3 d-flex align-items-center">
								<input class="form-check-input me-4 dark-checkbox" type="checkbox" value="" id="flexCheckDefault" />
								<label class="form-check-label" for="flexCheckDefault">
									Inventories
								</label>
							</div>
							<div class="form-check mx-4 my-3 d-flex align-items-center">
								<input class="form-check-input me-4 dark-checkbox" type="checkbox" value="" id="flexCheckChecked" />
								<label class="form-check-label" for="flexCheckChecked">
									Users
								</label>
							</div>
							<div class="form-check mx-4 my-3 d-flex align-items-center">
								<input class="form-check-input me-4 dark-checkbox" type="checkbox" value="" id="flexCheckDefault" />
								<label class="form-check-label" for="flexCheckDefault">
									Franchises
								</label>
							</div>
						</div>
						
					</div>
					<div className="form-group py-2 pb-3">
						<label className="font-weight-bold">Roles</label>
						<select className="w-75 py-2 px-3 form-select" aria-label="Default select example" outline="none">
							<option selected disabled>Inventory Manager</option>
							<option value="1">One</option>
							<option value="2">Two</option>
							<option value="3">Three</option>
						</select>
					</div>
				</div>
				<div className="col-lg-10 d-flex justify-content-end">
					<span className="cursorPointer">
						<svg className="bi bi-pencil border-bottom border-dark" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
							<path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z"/>
						</svg>
						<span className="mx-1">Edit</span>
					</span>
				</div>
			</div>
			
		</MainTemplate>
	);
}

export default AdminDetail;