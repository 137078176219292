import {
  Form,
  Button,
  FormGroup,
  FormControl,
  ControlLabel,
} from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import React from "react";
import { fontSize } from "@mui/system";
import "./ProductPrice.css";
import Tomato from "../../../assets/images/toppng 14.png";
import Edit from "../../../assets/images/Edit.png";
import Delete from "../../../assets/images/Delete.png";
import MainTemplate from "../../../components/MainTemplate";

import { ClassNames } from "@emotion/react";

function ProductPrice() {
  return (
    <MainTemplate categoryName="Categories" categoryList={["Admin", "Products",'Desi Tomato']}>
    <div className="container">
      <div className="row">
        <div className="row hight2"></div>
        <div className="col-6 md-3 ">
          <div className="inbox1 adjust">
            <div>
              <img src={Tomato} className="img" />
            </div>
          </div>
        </div>
        <div className="col-6 md-3 divWidth">
          <div className="row md-3">
            <div className="row rowhight md-3"></div>
            <div className="row">
              <div className="lables">
                <div className="border-properties">In stock</div>
                <div className="border2">15% OFF</div>
              </div>
            </div>
            <div className="hight1 md-3"></div>
            <div>
              <div className="row md-3">
                <h3 className="padding">
                  <b>Desi Tomato</b>
                </h3>
              </div>
            </div>
            <div className="row md-3 textbox linehight">
              <div className="lables">
                <div>ID:</div>
                <div>#654</div>
              </div>
              <hr></hr>
              <div className="lables">
                <div>Price:</div>
                <div>₹15</div>
              </div>
              <hr></hr>
              <div className="lables">
                <div>Cutout Price:</div>
                <div>₹23</div>
              </div>
              <hr></hr>
              <div className="lables">
                <div>Size:</div>
                <div>500 g</div>
              </div>
              <hr></hr>
              <div className="lables">
                <div>Shelf Life:</div>
                <div>7 Days</div>
              </div>
              <hr></hr>
              <div className="lables">
                <div>Commisions:</div>
                <div>5%</div>
              </div>
              <hr></hr>
              <div className="lables">
                <div>Category:</div>
                <div>Fruits & Vegetables</div>
              </div>
              <hr></hr>
              <div className="lables">
                <div>Sub Categorys:</div>
                <div>Vegetables</div>
              </div>
              <hr></hr>
            </div>
            <div className="hight3 md-3"></div>
            <div className="padding md-3">
              <h6>
                <b>Description</b>
              </h6>
            </div>
            <div className="row infobox md-3">
              <div>
                Tomatoes are a significant source of umami flavor. The tomato is
                consumed in diverse ways, raw or cooked, in many dishes, sauses,
                salads, and...<u className="colour"><b>read more</b></u>
              </div>
            </div>
            <div className="rowhight"></div>
            <div className="row md-3">
              <div className="lables imgcolor"><div className="col-7"></div><div><img src={Edit} />Edit</div><div><img src={Delete} /> Delete</div></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </MainTemplate>
  );
}

export default ProductPrice;
