import { createSlice } from '@reduxjs/toolkit'

export const userCartSlice = createSlice({
  name: 'userCart',
  initialState: { user: {}, products: [], selectedOrderType: null, error: null },
  reducers: {
    addUser: (state, action) => {
      return ({ ...state, user: action?.payload })
    },
    addUserAndProducts: (state, action) => {
      return ({ user: action?.payload?.user, products: action?.payload?.products })
    },
    addUserProducts: (state, action) => {
      const product = action?.payload?.products;
      const cartProducts = [...state?.products];
      const i = cartProducts.findIndex(el => el._id === product?._id);
      console.log(action, 'kkhkn');
      // If the cart is empty, allow adding the first product without order type check
      if (cartProducts.length === 0) {
        return {
          ...state,
          products: [{ ...product, qty: 1 }],
          selectedOrderType: action?.payload?.orderType // Set the order type for the first product
        };
      }

      // If product order type matches the existing order type or order type is not set yet
      if (state.selectedOrderType === action?.payload?.orderType) {
        if (i > -1) {
          cartProducts[i] = { ...product, qty: cartProducts[i]?.qty + 1 };
        } else {
          cartProducts.push({ ...product, qty: 1 });
        }

        return { ...state, products: cartProducts, error: null }; // Clear the error
      }


      // If product order type doesn't match existing order type, show an error
      if (state.selectedOrderType !== action?.payload?.orderType) {
        return {
          ...state,
          error: "Order type does not match the current cart order type."
        };
      }
    },
    removeUserProduct: (state, action) => {
      const product = action?.payload
      let products = [...state?.products]
      const i = products.findIndex(el => el._id === product._id);

      products = [...products.slice(0, i), ...products?.slice(i + 1)]
      return { ...state, products }
    },
    subtractUserProduct: (state, action) => {
      const product = action?.payload
      let products = [...state?.products]
      const i = products.findIndex(el => el._id === product._id);

      if (products[i]?.qty === 1) {
        products = [...products.slice(0, i), ...products?.slice(i + 1)]
      } else {
        products = [...products.slice(0, i), { ...product, qty: product?.qty - 1 }, ...products?.slice(i + 1)]
      }
      return { ...state, products }
    },
    emptyUserCart: (state, payload) => ({ user: {}, products: [] }),
  },
})

// Action creators are generated for each case reducer function
export const { addUser, addUserAndProducts, addUserProducts, removeUserProduct, subtractUserProduct, emptyUserCart, showError
} = userCartSlice.actions

export default userCartSlice.reducer

