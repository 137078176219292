// api.js

export const HIBEE_AUTH_URL = process.env.REACT_APP_HIBEE_AUTH_URL;
export const HIBEE_PRODUCT_URL = process.env.REACT_APP_HIBEE_PRODUCT_URL;
export const HIBEE_ADMIN_URL = process.env.REACT_APP_HIBEE_ADMIN_URL;
export const HIBEE_ORDER_URL = process.env.REACT_APP_HIBEE_ORDER_URL;
export const HIBEE_BULK_IMAGE = process.env.REACT_APP_HIBEE_BULK_IMAGE;
export const HIBEE_CART_URL = process.env.REACT_APP_HIBEE_CART_URL;



export const REACT_LOGIN_API_PATH = "/user/login";
export const REACT_SEND_OTP_API_PATH = "/send-otp";
export const REACT_VERIFY_OTP_API_PATH = "/verify-otp";
export const REACT_CHANGE_PASSWORD_API_PATH = "/user/change-password";
/** Revanue Dashboard **/
export const DASHBOARD = "/order/franchise/dashboard" 



/** Offline user **/
export const OFFLINE_USER ="/offline_user/register"

/** Franchise **/
export const REACT_NOTIFICATIONS_PATH = "/admin/notification";
export const SOCIETY_LIST = "/society";
export const FRANCHISE_LIST_API_PATH = '/frenchise'
export const FRANCHISE_PROFILE = '/frenchise'
export const UPDATE_FRANCHISE_API_PATH = "/frenchise";
export const CHANGE_PASSWORD = '/user/admin-change-password'
export const NOTIFICATION = '/admin/notification'
export const PRODUCT_PATH = '/product'
export const PRODUCT_LIST = '/product'
export const PRODUCT_SUBCATEGORY = "/product_category_lists"
export const FRANCHISE_USERS_LIST = '/frenchise/user_frenchise_list/'


/** CART **/
export const FRANCHISE_CART = "/cart"
export const FRANCHISE_USER_CART = "/cart-user"
export const FRANCHISE_INVENTORY_CART = "/cart-inventory"
export const FRANCHISE_OFFLINE_CART = "/cart-offline"

// order //
export const FRANCHISE_ORDER = "/order"
export const FRANCHISE_INVENTORY_ORDER = "/placeorder"
export const FRANCHISE_OFFLINE_ORDER = "/placeorder_offlineuser"
export const FRANCHISE_DELIVERY_STATUS = "/delivery_status"

// Inventory
export const INVENTORY_LIST = "/inventory"
export const CATEGORY_SUB_PATH = "/category/sub";
export const CATEGORY_SUB_LIST_PATH = "/category/categorySubList";
export const CATEGORY_PATH = "/category";


//society
export const SOCIETY = "/society"
export const USERS = "/user"

//COD 
export const COD_TRANSACTIONS = "/cod_transactions"
export const CODLIMIT = "/cod_value"