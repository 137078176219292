import React, { useEffect, useState } from 'react'
import MainTemplate from '../../components/MainTemplate'
import { FRANCHISE_PROFILE, FRANCHISE_USERS_LIST, HIBEE_PRODUCT_URL } from '../../api_interface/apiURLs'
import { useParams } from 'react-router-dom';
import { getTokenApi } from '../../api_interface/apis';

const UserInfo = () => {
  const [profiledata, setProfileData] = useState(null);
  const { _id } = useParams();
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getTokenApi(`${HIBEE_PRODUCT_URL}${FRANCHISE_USERS_LIST}/${_id}`);
        if (response && response.data && Array.isArray(response.data.users)) {
          const userList = response.data.users;
          const filteredData = userList.find((user) => user._id === _id);
          setProfileData(filteredData || null);
        } else {
          setProfileData(null);
        }
      } catch (error) {
        console.log('Error fetching user data:', error.message);
      }
    };
  
    fetchData();
  }, [_id]);
console.log(profiledata)
  return (
    <MainTemplate categoryName="User Information" categoryList={["Franchise", "User Information"]}>
        <div className='row'>
          <div className='col-12'>
         

          {profiledata ? (
            <div className='mt-4 p-5 rounded ' style={{backgroundColor:'white', width:"100%"}}>
              {/* <h5 className='fw-bold'> User Information</h5> */}
              {/* <p className='fs-4 mt-4'>ID: {profiledata._id}</p> */}
              <div className=' m-4'>
              <p className=' m-2 fw-bold'>Name </p><p className='m-2'>{profiledata.name}</p>
              </div>
              <div className='m-4'>
              <p className=' m-2 fw-bold'>Phone</p><p className='m-2 border-bottom border-1 border-dark' style={{width:"11%"}} >+91{' '}{profiledata.phone}</p>
              </div>
              {/* <div className='m-4'>
              <p className=' m-2 fw-bold'>society</p><p className='m-2'>{profiledata.society}</p>
              </div> */}
              <div className='m-4'>
              <p className=' m-2 fw-bold'>Email </p><p className='m-2'>{profiledata.email}</p>
              </div>
              <div className='m-4'>
              <p className=' m-2 fw-bold'>Flat Number</p><p className='m-2'>{profiledata.flat_no}</p>
              </div>
              <div className='m-4'>
              <p className=' m-2 fw-bold'>Floor</p><p className='m-2'>{profiledata.floor}</p>
              </div>
              <div className='m-4'>
              <p className=' m-2 fw-bold'>Tower</p><p className='m-2'>{profiledata.tower}</p>
              </div>
             
            
         


              {/* Render other user data as needed */}
            </div>
          ) : (
            <p>Loading user information...</p>
          )}
    </div>
          </div>

        


    </MainTemplate>
  )
}

export default UserInfo
