import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  notiCount: 0,
};

const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    setNotificationCount(state, action) {
      state.notiCount = action.payload;
    },
    resetNotificationCount(state) {
      state.notiCount = 0;
    },
  },
});

export const { setNotificationCount, resetNotificationCount } =
  notificationSlice.actions;

export default notificationSlice.reducer;