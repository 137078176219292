import React, { useState, useEffect, useMemo } from "react";
import { Link, useParams } from "react-router-dom";
import "./CategoryDetail.css";
  
import MainTemplate from "../../../components/MainTemplate";
import Modal from "react-modal";
import {
  customActionModalStyles,
  customModalStyles,
  debounce,
} from "../../../utils/helperFunctin";
import {
  CATEGORY_PATH,
  CATEGORY_SUB_LIST_PATH,
  CATEGORY_SUB_PATH,
  HIBEE_PRODUCT_URL,
} from "../../../api_interface/apiURLs";
import {
  deleteTokenApi,
  getTokenApi,
  postTokenApi,
} from "../../../api_interface/apis";
import { toast } from "react-toastify";
// import dummycsv from "../../../assets/dummy.csv";

function CategoryDetail() {
  const { categoryId } = useParams();

  console.log(categoryId,'categoryIdcategoryId');

  const [modal, setModal] = useState(false);
  const [query, setQuery] = useState("");
  const [productlist, setProductList] = useState([])
  const [subCatModel, setSubCatModel] = useState({
    show: false,
    data: {},
    loader: false,
  });
  const [error, setError] = useState({});
  const [actionModal, setActionModal] = useState({
    loader: false,
    show: false,
    type: "",
    data: {},
  });
  const [category, setCategory] = useState({});

  const getData = async (search) => {
    const res = await postTokenApi(
      HIBEE_PRODUCT_URL +
      CATEGORY_SUB_LIST_PATH
      +
        `/${categoryId}?search=${query}&status=${true}`
    );
    setCategory(res?.data?.message?.[0]);
    const productsList = res?.data.message 
    setProductList(productsList)

  };

  console.log(subCatModel);
  const validation = () => {
    const { name, image, discount, commission, imageFile, _id, csvfile } =
      subCatModel?.data;
    let error = {};
      if (!name) {
        error.name = "Name Required";
      }
      if (!discount) {
        error.discount = "Discount Required";
      }
      if (!commission) {
        error.commission = "Commission Required";
      }
      if (!(image || imageFile)) {
        error.image = "Image Required";
      }
      if (Object.keys(error)?.length) {
        setError(error);
        return false;
      } else {
        setError({});
        return true;
      }
  };

  const submit = async (e) => {
    e.preventDefault();
    try {
      setSubCatModel({ ...subCatModel, loader: true });
      if (validation()) {
        const fd = new FormData();
        fd.append("name", subCatModel?.data?.name);
        if (subCatModel?.data?.imageFile) {
          fd.append("image", subCatModel?.data?.imageFile);
        }
        if (subCatModel?.data?._id) {
          fd.append("_id", subCatModel?.data?._id);
        }
        const res = await postTokenApi(
          HIBEE_PRODUCT_URL + CATEGORY_SUB_PATH + `/${category?._id}`,
          fd,
          { "Content-Type": "multipart/form-data" }
        );
        getData();
        setSubCatModel({});
      } else {
        setSubCatModel({ ...subCatModel, loader: false });
      }
    } catch (err) {
      const message = err?.response?.data?.message || "Something went wrong";
      toast.error(message, { position: "top-center", autoClose: 2000 });
    }
  };

  const deleteSubCategory = async (id) => {
    setActionModal({ ...actionModal, loader: true });
    const res = await deleteTokenApi(
      HIBEE_PRODUCT_URL + CATEGORY_SUB_PATH + `/${id}`
    );
    setActionModal({});
    getData();
   
  };
  function downloadFile(url) {
    const link = document.createElement("a");
    link.href = url;
    link.download = url.split("/").pop();
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
  const archieveSubCategory = async (id) => {
    setActionModal({ ...actionModal, loader: true });
    setActionModal({});
    getData();
  };

  useEffect(() => {
    getData();
  }, []);

  const getSearchedData = useMemo(
    () =>
      debounce(async (search) => {
        getData(search);
      }, 500),
    []
  );

  useEffect(() => {
    getSearchedData(query);
  }, [query]);

  useEffect(() => {
    if (Object.keys(error)?.length) {
      validation();
    }
  }, [error]);

  return (
    <MainTemplate
      categoryName={category && category?.name}
      categoryList={["Categories", "Categories", category && category?.name]}
    >
      <div className="row p-0">
        <div className="col-lg-6 p-0 pe-3">
          <div className="m-3 p-4 mx-0 mt-0 rounded bg-white ">
            <div className="border p-4 rounded">
              <div className="p-2 bg-light-blue d-flex justify-content-center categoryDetailImageConatiner">
                <img
                  src={category?.image}
                  alt=""
                  style={{ width: "100%", height: "100%", objectFit: "cover" }}
                />
              </div>
              <div>
                <div className="mt-2 font-weight-bold t-16">
                  {category?.name}
                </div>
                <div className="t-16">
                  Total Sub Category:
                  <span className="font-weight-bold">
                    {category?.SubCategorys?.length} sub categories
                  </span>
                </div>
                <div className="t-16">
                  Total Products:
                  <span className="font-weight-bold">
                   
                    {category?.ProductCount} products
                  </span>
                </div>
              </div>
              <div className="mt-4 d-flex justify-content-between align-items-center t-12">
                <span
                  className="d-flex align-items-center cursorPointer"
                  // onClick={() => setModal(true)}
                >
                  {/* <svg
                    className="bi bi-pencil border-bottom border-dark"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    viewBox="0 0 16 16"
                  >
                    <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" />
                  </svg> */}
                  {/* <span className="mx-1">Edit</span> */}
                </span>
                {/* <button
                  type="button"
                  className="btn btn-outline-dark"
                  onClick={() =>
                    setSubCatModel({ show: true, data: {}, loader: false })
                  }
                >
                  Add Sub Category
                </button> */}
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-6 p-0 pe-3">
          <div className="m-3 p-3 mx-0 mt-0 rounded bg-white">
            <div className="font-weight-bold t-16 d-flex mb-3 align-items-center justify-content-between">
              Sub Categories
              {/* <input
                type="text"
                name="query"
                className="w-50 t-12 form-control"
                placeholder="Search"
                value={query}
                onChange={(e) => setQuery(e.target.value)}
              /> */}
            </div>
            <div className="row font-weight-bold t-12 mb-3 px-3">
              <div className="col-2  px-1">Photo</div>
              <div className="col-4 px-1 d-flex justify-content-center">Sub Category Name</div>
              <div className="col-3 px-1">Product count</div>
            </div>
           
            <div className="overflow-auto categoryDetailSubCat pe-2">
              {category?.SubCategorys?.map((el) => (
                <div
                  className="row border rounded m-0 px-2 mb-2 d-flex align-items-center"
                  key={el?._id}
                >
                 
                  <div className="t-16 text-uppercase my-3 col-md-2">
                    <div className="categoryImageDiv shadow-sm  rounded p-1">
                      <Link to={`/inventory-product-listing/${el?._id}`}>
                      {el?.images && el.images.length > 0 ? (
                      <img src={el.images[0].image} alt="loading" className="categoryImage h-100 rounded" />
                    ) : (
                      <img src={el?.image} alt="loading" className="categoryImage h-100 rounded" />
                    )}                      </Link>
                    </div>
                  </div>
                  <div className="t-16 text-uppercase my-3 col-md-4 d-flex justify-content-center">
                    <Link
                      to={`/inventory-product-listing/${el?._id}`}
                      className="text-dark "
                      style={{ textDecoration: "none" }}
                    >
                      {el?.name}
                    </Link>
                  </div>
                  <div className="t-16 text-uppercase my-3 col-md-3 justify-content-center">
                    {el?.ProductCount} Products
                  </div>
                  <div className="t-16 my-3 col-md-1">
                    <div class="dropdown">
                      {/* <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        data-menu-target={`drop-down-menu-${el?._id}`}
                        className="bi bi-three-dots dropdown-btn cursorPointer"
                        viewBox="0 0 16 16"
                      >
                        <path d="M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z" />
                      </svg> */}
                      {/* <button class="dropbtn">Dropdown</button> */}
                      <div
                        id={`drop-down-menu-${el?._id}`}
                        className="dropdown-content p-2"
                      >
                        <div
                          className="actionMenu rounded px-3 py-2 d-flex cursorPointer"
                          onClick={() =>
                            setSubCatModel({
                              ...subCatModel,
                              show: true,
                              data: el,
                            })
                          }
                        >
                          {/* <span className="mx-2 d-flex align-items-center">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              class="bi bi-pencil"
                              viewBox="0 0 16 16"
                            >
                              <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" />
                            </svg>
                          </span>
                          <span className="mx-2">
                            <div className="font-weight-bold">Edit</div>
                            <div className="">Edit this product</div>
                          </span> */}
                        </div>
                        <div
                          className="actionMenu rounded px-3 py-2 d-flex cursorPointer"
                          onClick={() =>
                            setActionModal({
                              ...actionModal,
                              show: true,
                              data: el,
                              type: "delete",
                            })
                          }
                        >
                          {/* <span className="mx-2 d-flex align-items-center">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              class="bi bi-trash3"
                              viewBox="0 0 16 16"
                            >
                              <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
                            </svg>
                          </span>
                          <span className="mx-2">
                            <div className="font-weight-bold">Delete</div>
                            <div className="">Delete this product</div>
                          </span> */}
                        </div>
                        {/* <div className="actionMenu rounded px-3 py-2 d-flex cursorPointer">
														<span className="mx-2 d-flex align-items-center">
															<svg width="16" height="16" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
																<path d="M2.44444 22C1.77222 22 1.19656 21.7609 0.717444 21.2826C0.239148 20.8034 0 20.2278 0 19.5556V4.27778C0 3.97222 0.0509259 3.69722 0.152778 3.45278C0.25463 3.20833 0.387037 2.97407 0.55 2.75L2.26111 0.672222C2.42407 0.448148 2.62778 0.279889 2.87222 0.167445C3.11667 0.0558149 3.38148 0 3.66667 0H18.3333C18.6185 0 18.8833 0.0558149 19.1278 0.167445C19.3722 0.279889 19.5759 0.448148 19.7389 0.672222L21.45 2.75C21.613 2.97407 21.7454 3.20833 21.8472 3.45278C21.9491 3.69722 22 3.97222 22 4.27778V19.5556C22 20.2278 21.7609 20.8034 21.2826 21.2826C20.8034 21.7609 20.2278 22 19.5556 22H2.44444ZM2.93333 3.66667H19.0667L18.0278 2.44444H3.97222L2.93333 3.66667ZM2.44444 6.11111V19.5556H19.5556V6.11111H2.44444ZM11 18.3333L15.8889 13.4444L14.1778 11.7333L12.2222 13.6889V8.55556H9.77778V13.6889L7.82222 11.7333L6.11111 13.4444L11 18.3333ZM2.44444 19.5556H19.5556H2.44444Z" fill="#666666"/>
															</svg>
														</span>
														<span className="mx-2">
															<div className="font-weight-bold">Archive</div>
															<div className="">Archive this product</div>
														</span>
													</div> */}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      {/* Sub Catgory model */}
      <Modal
        isOpen={subCatModel?.show}
        onRequestClose={() => setSubCatModel({ data: {} })}
        style={customModalStyles}
        contentLabel="SubCategory Modal"
        ariaHideApp={false}
      >
        <div className="px-3 py-3">
          <div className="row">
            <h4>Add Category Form</h4>

            <span className="d-flex justify-content-end cursorPointer">
              <svg
                onClick={() => setSubCatModel({ data: {} })}
                className="d-inline"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                viewBox="0 0 16 16"
              >
                <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
              </svg>
            </span>
          </div>
          <div className="row">
            <div className="form-group my-2">
              <label className="">Sub Category Name</label>
              <input
                name="name"
                onChange={(e) =>
                  setSubCatModel({
                    ...subCatModel,
                    data: { ...subCatModel?.data, name: e.target.value },
                  })
                }
                type="text"
                className={`placeholder-light form-control my-2 py-2 px-3 ${
                  error?.name && "border-danger"
                }`}
                placeholder="eg: Apple New Launch Pad"
              />
              <span className="text-danger">{error?.name}</span>
            </div>
          </div>
          <div className="row">
            <div className="form-group my-2">
              <label className="">Commission</label>
              <input
                name="commission"
                value={category?.commission}
                onChange={(e) =>
                  setSubCatModel({
                    ...subCatModel,
                    data: { ...subCatModel?.data, commission: e.target.value },
                  })
                }
                type="text"
                autoComplete="off"
                className={`placeholder-light form-control my-2 py-2 px-3 ${
                  error?.commission && "border-danger"
                }`}
                placeholder="eg: Apple New Launch Pad"
              />
              <span className="text-danger">{error?.commission}</span>
            </div>
          </div>
          <div className="row">
            <div className="form-group my-2">
              <label className="">Discount</label>
              <input
                name="discount"
                value={category?.discount}
                onChange={(e) =>
                  setSubCatModel({
                    ...subCatModel,
                    data: { ...subCatModel?.data, discount: e.target.value },
                  })
                }
                type="number"
                autoComplete="off"
                className={`placeholder-light form-control my-2 py-2 px-3 ${
                  error?.discount && "border-danger"
                }`}
                placeholder="eg: Apple New Launch Pad"
              />
              <span className="text-danger">{error?.discount}</span>
            </div>
          </div>
          <div className="row">
            <div className="form-group my-2">
              <label className="">Upload Image</label>
              <input
                name="image"
                type="file"
                onChange={(e) =>
                  setSubCatModel({
                    ...subCatModel,
                    data: {
                      ...subCatModel?.data,
                      image: "",
                      imageFile: e.target?.files?.[0],
                    },
                  })
                }
                accept="image/png, image/gif, image/jpeg"
                className="py-2 opacity0 position-absolute placeholder-light form-control py-2 px-3"
                placeholder="eg: https://www.apple.com/in/watch"
              />
              <div
                className={`my-2 py-2 select-text-light d-flex align-items-center px-3 border rounded ${
                  error?.image && "border-danger"
                }`}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-cloud-arrow-up"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M7.646 5.146a.5.5 0 0 1 .708 0l2 2a.5.5 0 0 1-.708.708L8.5 6.707V10.5a.5.5 0 0 1-1 0V6.707L6.354 7.854a.5.5 0 1 1-.708-.708l2-2z"
                  />
                  <path d="M4.406 3.342A5.53 5.53 0 0 1 8 2c2.69 0 4.923 2 5.166 4.579C14.758 6.804 16 8.137 16 9.773 16 11.569 14.502 13 12.687 13H3.781C1.708 13 0 11.366 0 9.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383zm.653.757c-.757.653-1.153 1.44-1.153 2.056v.448l-.445.049C2.064 6.805 1 7.952 1 9.318 1 10.785 2.23 12 3.781 12h8.906C13.98 12 15 10.988 15 9.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 4.825 10.328 3 8 3a4.53 4.53 0 0 0-2.941 1.1z" />
                </svg>
                <span className="px-2">Upload your image file</span>
              </div>
              <span className="text-danger">{error?.image}</span>
              {subCatModel?.data?.image ||
              (subCatModel?.data?.imageFile &&
                URL.createObjectURL(subCatModel?.data?.imageFile)) ? (
                <div>
                  <img
                    id="categoryPageModalImage"
                    src={
                      subCatModel?.data?.image ||
                      URL.createObjectURL(subCatModel?.data?.imageFile)
                    }
                    alt=""
                    className="rounded shadow-sm mt-2"
                    style={{
                      width: "100px",
                      height: "100px",
                      objectFit: "cover",
                    }}
                  />
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
          <div>
            <p className="d-flex justify-content-center">or</p>
            <div>
              <h6>Uploading many categories at once?</h6>
              <p className="select-text-light">
                Upload a file in a .csv format
                {/* <span
                  className="text-success fw-bold"
                  role="button"
                  onClick={() => downloadFile(dummycsv)}
                >
                  Download sample format here
                </span> */}
              </p>

              {/* <input
                name="CSV"
                type="file"
                autoComplete="off"
                accept=".csv"
                value={subCatModel?.data.csvfile}
                onChange={(e) =>
                  setSubCatModel({
                    ...subCatModel,
                    data: {
                      ...subCatModel?.data,
                      csvfile: "",
                      csvfile: e.target?.files?.[0],
                    },
                  })
                }
                className={`py-2 opacity0 position-absolute  w-25 placeholder-light form-control py-2 px-3 border`}
                placeholder="eg: https://www.apple.com/in/watch"
              /> */}
              <div
                className={`my-2 py-2 select-text-light w-25 d-flex align-items-center px-3 border rounded ${
                  error?.image && "border-danger"
                }`}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-cloud-arrow-up"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M7.646 5.146a.5.5 0 0 1 .708 0l2 2a.5.5 0 0 1-.708.708L8.5 6.707V10.5a.5.5 0 0 1-1 0V6.707L6.354 7.854a.5.5 0 1 1-.708-.708l2-2z"
                  />
                  <path d="M4.406 3.342A5.53 5.53 0 0 1 8 2c2.69 0 4.923 2 5.166 4.579C14.758 6.804 16 8.137 16 9.773 16 11.569 14.502 13 12.687 13H3.781C1.708 13 0 11.366 0 9.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383zm.653.757c-.757.653-1.153 1.44-1.153 2.056v.448l-.445.049C2.064 6.805 1 7.952 1 9.318 1 10.785 2.23 12 3.781 12h8.906C13.98 12 15 10.988 15 9.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 4.825 10.328 3 8 3a4.53 4.53 0 0 0-2.941 1.1z" />
                </svg>
                <span className="px-2">Upload</span>
              </div>
            </div>
          </div>
          <div className="form-group my-3">
            <button
              disabled={subCatModel?.loader}
              className="btn btn-dark text-warning px-5"
              onClick={submit}
            >
              {subCatModel?.loader ? (
                <div class="spinner-border spinner-border-sm" role="status">
                  <span class="sr-only"></span>
                </div>
              ) : (
                "Submit"
              )}
            </button>
          </div>
        </div>
      </Modal>

      {/* <Modal
        isOpen={modal}
        onRequestClose={() => setModal(false)}
        style={customModalStyles}
        contentLabel="Example Modal"
        ariaHideApp={false}
      >
        <div className="px-5 py-3">
          <div className="row">
            <span className="d-flex justify-content-end cursorPointer">
              <svg
                onClick={() => setModal(false)}
                className="d-inline"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                viewBox="0 0 16 16"
              >
                <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
              </svg>
            </span>
          </div>
          <div className="modalMaxHeight">
            <div className="row">
              <div className="form-group my-2">
                <label className="my-1">Upload Image</label>
                <input
                  type="file"
                  className="py-2 opacity0 position-absolute placeholder-light form-control py-2 px-3"
                  placeholder="eg: https://www.apple.com/in/watch"
                />
                <div className="my-1 py-2 select-text-light d-flex align-items-center px-3 border rounded">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-cloud-arrow-up"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M7.646 5.146a.5.5 0 0 1 .708 0l2 2a.5.5 0 0 1-.708.708L8.5 6.707V10.5a.5.5 0 0 1-1 0V6.707L6.354 7.854a.5.5 0 1 1-.708-.708l2-2z"
                    />
                    <path d="M4.406 3.342A5.53 5.53 0 0 1 8 2c2.69 0 4.923 2 5.166 4.579C14.758 6.804 16 8.137 16 9.773 16 11.569 14.502 13 12.687 13H3.781C1.708 13 0 11.366 0 9.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383zm.653.757c-.757.653-1.153 1.44-1.153 2.056v.448l-.445.049C2.064 6.805 1 7.952 1 9.318 1 10.785 2.23 12 3.781 12h8.906C13.98 12 15 10.988 15 9.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 4.825 10.328 3 8 3a4.53 4.53 0 0 0-2.941 1.1z" />
                  </svg>
                  <span className="px-2">Upload your image file</span>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-6 d-flex align-items-center">
                <span>ID:</span>
              </div>
              <div className="col-6">
                <input
                  className="placeholder-light form-control my-1 py-2 px-3"
                  type="text"
                  placeholder="#654"
                />
              </div>
            </div>
            <div className="row">
              <div className="col-6 d-flex align-items-center">
                <span>Price:</span>
              </div>
              <div className="col-6">
                <input
                  className="placeholder-light form-control my-1 py-2 px-3"
                  type="number"
                  placeholder="₹"
                />
              </div>
            </div>
            <div className="row">
              <div className="col-6 d-flex align-items-center">
                <span>Cutout Price:</span>
              </div>
              <div className="col-6">
                <input
                  className="placeholder-light form-control my-1 py-2 px-3"
                  type="number"
                  placeholder="₹"
                />
              </div>
            </div>
            <div className="row">
              <div className="col-6 d-flex align-items-center">
                <span>Size:</span>
              </div>
              <div className="col-6">
                <select
                  className="p-2 px-3 my-1 form-select select-text-light"
                  aria-label="Default select example"
                  outline="none"
                >
                  <option selected>Select in gm</option>
                  <option value="1">100gm</option>
                  <option value="2">150gm</option>
                  <option value="3">500gm</option>
                </select>
              </div>
            </div>
            <div className="row">
              <div className="col-6 d-flex align-items-center">
                <span>Size:</span>
              </div>
              <div className="col-6">
                <input
                  className="placeholder-light form-control my-1 py-2 px-3"
                  type="number"
                  placeholder="Qty"
                />
              </div>
            </div>
            <div className="row">
              <div className="col-6 d-flex align-items-center">
                <span>Shelf Life:</span>
              </div>
              <div className="col-6">
                <input
                  className="placeholder-light form-control my-1 py-2 px-3"
                  type="number"
                  placeholder="Days"
                />
              </div>
            </div>
            <div className="row">
              <div className="col-6 d-flex align-items-center">
                <span>Commisions:</span>
              </div>
              <div className="col-6">
                <input
                  className="placeholder-light form-control my-1 py-2 px-3"
                  type="number"
                  placeholder="In %"
                />
              </div>
            </div>
            <div className="row">
              <div className="col-6 d-flex align-items-center">
                <span>Category:</span>
              </div>
              <div className="col-6">
                <select
                  className="p-2 px-3 my-1 form-select select-text-light"
                  aria-label="Default select example"
                  outline="none"
                >
                  <option selected>Fruit & Vegetable</option>
                  <option value="1">Dairy</option>
                  <option value="2">Chips</option>
                  <option value="3">Beverage</option>
                </select>
              </div>
            </div>
            <div className="row">
              <div className="col-6 d-flex align-items-center">
                <span>Sub Category:</span>
              </div>
              <div className="col-6">
                <select
                  className="p-2 px-3 my-1 form-select select-text-light"
                  aria-label="Default select example"
                  outline="none"
                >
                  <option selected>Fruits</option>
                  <option value="1">Vegetable</option>
                  <option value="2">Fruits</option>
                  <option value="3">Root vegetables</option>
                </select>
              </div>
            </div>
            <div className="row">
              <div className="form-group my-2">
                <label className="font-weight-bold my-1">Description</label>
                <textarea
                  className="placeholder-light form-control py-2 px-3"
                  placeholder="Notification Description"
                  rows="6"
                ></textarea>
              </div>
            </div>
          </div>

          <div className="form-group my-3">
            <button className="btn btn-dark text-warning px-5">Submit</button>
          </div>
        </div>
      </Modal> */}

      {/* Action Model */}
      <Modal
        isOpen={actionModal?.show}
        onRequestClose={() => setActionModal({})}
        style={customActionModalStyles}
        contentLabel="Action Modal"
        ariaHideApp={false}
      >
        <div className="px-3 py-3">
          <div className="row">
            <span className="d-flex justify-content-end cursorPointer">
              <svg
                onClick={() => setActionModal({})}
                className="d-inline"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                viewBox="0 0 16 16"
              >
                <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
              </svg>
            </span>
          </div>
          <div className="row">
            <h4 className="text-danger d-flex justify-content-center">
              Do you want to {actionModal?.type} Category?
            </h4>
          </div>
          <div className="row my-5">
            <h5 className="d-flex justify-content-center">
              {actionModal?.data?.name}
            </h5>
          </div>
          <div className="form-group my-3 d-flex justify-content-center">
            <button
              disabled={actionModal?.loader}
              className="btn btn-dark me-3 mb-2 text-warning px-5"
              onClick={() =>
                actionModal?.type === "delete"
                  ? deleteSubCategory(actionModal?.data?._id)
                  : archieveSubCategory(actionModal?.data?._id)
              }
            >
              {actionModal?.loader ? (
                <div class="spinner-border spinner-border-sm" role="status">
                  <span class="sr-only"></span>
                </div>
              ) : (
                "Submit"
              )}
            </button>
            <button
              disabled={actionModal?.loader}
              className="btn btn-light me-3 mb-2 shadow-sm text-dark border px-5"
              onClick={() => setActionModal({})}
            >
              Cancel
            </button>
          </div>
        </div>
      </Modal>
    </MainTemplate>
  );
}

export default CategoryDetail;