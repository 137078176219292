import React, { useState, useEffect, } from "react";
import { useSelector, useDispatch } from 'react-redux';
import Modal from "react-modal";
import {
    HIBEE_ADMIN_URL,
    REACT_NOTIFICATIONS_PATH,
} from "../api_interface/apiURLs";
import { getTokenApi, patchTokenApi } from "../api_interface/apis";
import { Link } from "react-router-dom";
import {
    setNotificationCount,
    resetNotificationCount,
} from "../redux/reducers/notificationslice";


const Breadcrumb = ({ name, list }) => {
    const [notification, showNotification] = useState(false);
    const [notificationTab, setNotificationTab] = useState("Orders");
    const [orders, setOrders] = useState([]);
    const [inventory, setInventory] = useState([]);
    const [count, setCount] = useState()
    const [apiData, setApiData] = useState([])
    const dispatch = useDispatch();
    const inventoryCartProductLength = useSelector(state => state?.inventoryCartReducer?.products?.length)
    const userCartProductLength = useSelector(state => state?.userCartReducer?.products?.length)
    const [newOrderCount, setNewOrderCount] = useState(0);
    const [newInventoryCount, setNewInventoryCount] = useState(0);


    const [loader, setLoader] = useState(false);
    const handleNotificationClick = () => {
        showNotification(true);
        dispatch(resetNotificationCount());
    };
    const getData = async (search) => {
        try {
            const res = await getTokenApi(HIBEE_ADMIN_URL + REACT_NOTIFICATIONS_PATH);
            if (res?.data) {
                const datas = res.data?.data;
                setApiData(datas);

                if (Array.isArray(datas)) {
                    const filteredDatas = datas?.filter((item) => item?.type === "order");
                    setOrders(filteredDatas);

                    // Retrieve the old orders count from local storage
                    const oldOrdersCount = parseInt(localStorage.getItem("oldorderscount") || "0", 10);

                    // Calculate the difference and update the newOrdersCount state
                    const newOrdersCount = Math.max(0, filteredDatas.length - oldOrdersCount);
                    setNewOrderCount(newOrdersCount);
                }

                if (Array.isArray(datas)) {
                    const filteredData = datas?.filter((item) => item?.type === "inventory");
                    setInventory(filteredData);

                    // Retrieve the old inventory count from local storage
                    const oldInventoryCount = parseInt(localStorage.getItem("oldinventorycount") || "0", 10);

                    // Calculate the difference and update the newInventoryCount state
                    const newInventoryCount = Math.max(0, filteredData.length - oldInventoryCount);
                    setNewInventoryCount(newInventoryCount);

                    // Store the current inventory count in local storage if notification is true
                }
            }
        } catch (err) {
            setLoader(false);
            console.log(err);
        }
    };

    useEffect(() => {
        // Call getData initially
        getData();

        // Set up the interval to call getData every 2 seconds
        const interval = setInterval(getData, 5000);

        // Clean up the interval when the component is unmounted
        return () => clearInterval(interval);
    }, []); // Empty dependency array ensures this effect runs only once, on component mount

    useEffect(() => {
        if (notification) {
            localStorage.setItem("oldorderscount", orders.length.toString());
            localStorage.setItem("oldinventorycount", inventory.length.toString());
        }

    }, [notification])




    const customStyles = {
        content: {
            top: "0",
            left: "0",
            right: "0",
            bottom: "0",
            position: "absolute",
            height: "100vh",
            background: "rgba(0,0,0,0.1)",
            outline: "none",
            border: "none",
        },
        overlay: {
            position: "absolute",
            heigth: "100vh",
            background: "rgba(0,0,0,0.6)",
            zIndex: "99999",
        },
    };

    return (
        <>
            <div className="row">
                <div className="col-4 p-0 mb-4">
                    <div>
                        <h5 className="mb-0 font-weight-bold">{name}</h5>
                        {
                            list?.map((el, index) => (
                                <span className={`t-12 ${index + 1 === list?.length && "font-weight-bold"}`}>
                                    {el}
                                    {index + 1 !== list?.length && " > "}
                                </span>
                            ))
                        }
                    </div>
                </div>
                <div className="col-8 d-flex justify-content-end">
                    <span className="position-relative mx-2" onClick={handleNotificationClick} style={{ cursor: "pointer" }} >
                        {newOrderCount + newInventoryCount != 0 ?
                            <span className="badge badge-pill badge-warning position-absolute">
                                {newOrderCount + newInventoryCount}
                            </span> : ""}                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" stroke="black" stroke-width=".1" fill="currentColor" className="font-weight-bold bi bi-bell" viewBox="0 0 16 16">
                            <path d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2zM8 1.918l-.797.161A4.002 4.002 0 0 0 4 6c0 .628-.134 2.197-.459 3.742-.16.767-.376 1.566-.663 2.258h10.244c-.287-.692-.502-1.49-.663-2.258C12.134 8.197 12 6.628 12 6a4.002 4.002 0 0 0-3.203-3.92L8 1.917zM14.22 12c.223.447.481.801.78 1H1c.299-.199.557-.553.78-1C2.68 10.2 3 6.88 3 6c0-2.42 1.72-4.44 4.005-4.901a1 1 0 1 1 1.99 0A5.002 5.002 0 0 1 13 6c0 .88.32 4.2 1.22 6z" />
                        </svg>

                    </span>
                    <Link to={'/user-cart/:userId'}>
                        <span className="position-relative mx-2">
                            {Boolean(userCartProductLength) && <span className="badge badge-pill badge-warning position-absolute">{userCartProductLength}</span>}
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="font-weight-bold bi bi-cart3" viewBox="0 0 16 16">
                                <path d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .49.598l-1 5a.5.5 0 0 1-.465.401l-9.397.472L4.415 11H13a.5.5 0 0 1 0 1H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5zM3.102 4l.84 4.479 9.144-.459L13.89 4H3.102zM5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm-7 1a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm7 0a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
                            </svg>
                        </span>
                    </Link>
                    <Link to={"/inventory-cart"}>
                        <span className="position-relative mx-2">
                            {Boolean(inventoryCartProductLength) && <span className="badge badge-pill badge-warning position-absolute">{inventoryCartProductLength}</span>}
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-basket" viewBox="0 0 16 16">
                                <path d="M5.757 1.071a.5.5 0 0 1 .172.686L3.383 6h9.234L10.07 1.757a.5.5 0 1 1 .858-.514L13.783 6H15a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1v4.5a2.5 2.5 0 0 1-2.5 2.5h-9A2.5 2.5 0 0 1 1 13.5V9a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h1.217L5.07 1.243a.5.5 0 0 1 .686-.172zM2 9v4.5A1.5 1.5 0 0 0 3.5 15h9a1.5 1.5 0 0 0 1.5-1.5V9H2zM1 7v1h14V7H1zm3 3a.5.5 0 0 1 .5.5v3a.5.5 0 0 1-1 0v-3A.5.5 0 0 1 4 10zm2 0a.5.5 0 0 1 .5.5v3a.5.5 0 0 1-1 0v-3A.5.5 0 0 1 6 10zm2 0a.5.5 0 0 1 .5.5v3a.5.5 0 0 1-1 0v-3A.5.5 0 0 1 8 10zm2 0a.5.5 0 0 1 .5.5v3a.5.5 0 0 1-1 0v-3a.5.5 0 0 1 .5-.5zm2 0a.5.5 0 0 1 .5.5v3a.5.5 0 0 1-1 0v-3a.5.5 0 0 1 .5-.5z" />
                            </svg>
                        </span>
                    </Link>
                </div>
            </div>

            <Modal
                isOpen={notification}
                onRequestClose={() => showNotification(false)}
                style={customStyles}
            >
                <div
                    className={`px-3 border-right position-absolute d-block bg-white rounded shadow-lg`}
                    style={{
                        width: "350px",
                        top: 0,
                        right: 0,
                        height: "100vh",
                        zIndex: "999",

                    }}
                >
                    <div className="my-4">
                        <div className="h4 d-flex justify-content-between align-items-center">
                            <span>Notifications</span>
                            <svg
                                onClick={() => showNotification(false)}
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                class="bi bi-x-lg"
                                viewBox="0 0 16 16"
                            >
                                <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
                            </svg>
                        </div>
                    </div>

                    <div className="my-4">
                        <div className="row d-flex justify-content-between align-items-center">
                            <div className="col-6 pe-2">
                                <button
                                    className={`t-12 w-100 btn ${notificationTab === "Orders"
                                        ? "btn-dark text-warning"
                                        : "btn-outline-dark"
                                        }`}
                                    onClick={() => setNotificationTab("Orders")}
                                >
                                    Orders Tab
                                </button>
                            </div>
                            <div className="col-6 pe-2">
                                <button
                                    className={`t-12 w-100 btn ${notificationTab === "inventory"
                                        ? "btn-dark text-warning"
                                        : "btn-outline-dark"
                                        }`}
                                    onClick={() => setNotificationTab("inventory")}
                                >
                                    Inventory
                                </button>
                            </div>
                        </div>
                    </div>

                    <hr className="my-4" />

                    {notificationTab === "inventory" && (
                        <>
                            {/* <div className="my-5">
                                <div className="row px-2 d-flex justify-content-between align-items-center ">
                                    <select
                                        className="t-12 form-select"
                                        aria-label="Default select example"
                                        outline="none"
                                    >
                                        <option selected>All Notifications</option>
                                        <option value="1">Expering Soon</option>
                                        <option value="2">Low on Stock</option>
                                    </select>
                                </div>
                            </div> */}

                            <div className=" ps-2 pe-3 mb-3">
                                {loader ? (
                                    <div className="text-center mt-5">
                                        <button
                                            class="btn mt-5"
                                            style={{ backgroundColor: "#FFEDC2" }}
                                            type="button"
                                        >
                                            <span
                                                class="spinner-border spinner-border-sm"
                                                role="status"
                                                aria-hidden="true"
                                            ></span>{" "}
                                            Loading...
                                        </button>
                                    </div>
                                ) : (
                                    inventory?.map((el) => (<>
                                        <div>
                                            <div className="t-12 row px-2 font-weight-bold mb-0">
                                                {el.product_name} Running low on quantity
                                            </div>
                                            <div className="row px-2 t-12">
                                                <div className="col-7 p-0 mb-3">Only {el.sizeList.quantity} apples left</div>
                                                <div className="col-5 p-0 mb-3 cursorPointer d-flex justify-content-end">
                                                    <Link to="/addinventory" state={{ data: el, edit: true }}
                                                    >View Details</Link>
                                                </div>
                                            </div>
                                        </div>
                                        <hr className="mb-3 mt-0 w-100" />
                                    </>)

                                    ))}
                            </div>

                            <div>
                                <u className="d-flex justify-content-end font-weight-bold t-12 mb-4">
                                    <span className="cursorPointer d-inline-block">View All</span>
                                </u>
                            </div>
                        </>
                    )}
                    {notificationTab == "Orders" && (
                        <>


                            <div className="notificationScroll ps-2 pe-3 mb-3">
                                {loader ? (
                                    <div className="text-center mt-5">
                                        <button
                                            class="btn mt-5"
                                            style={{ backgroundColor: "#FFEDC2" }}
                                            type="button"
                                        >
                                            <span
                                                class="spinner-border spinner-border-sm"
                                                role="status"
                                                aria-hidden="true"
                                            ></span>{" "}
                                            Loading...
                                        </button>
                                    </div>
                                ) : (
                                    orders?.map((el) => (
                                        <>
                                            <div className="">
                                                <div className="t-12 row px-2 font-weight-bold mb-0">
                                                    Order no. #{el?.order._id.slice(0, 5)}
                                                </div>
                                                <div className="row px-2 t-12">
                                                    <div className="col-7 p-0 mb-0">
                                                        {el?.order?.products.length} Items
                                                    </div>
                                                    <div className="col-7 p-0 mb-3">
                                                        {el?.order.status == 'cancelled' ? "Order Cancelled" : el?.order?.societyDeatils?.societyName}
                                                    </div>
                                                    <div className="col-5 p-0 mb-3 cursorPointer d-flex justify-content-end">
                                                        <Link className="" to={`/order-details/${el?.order._id}`}>
                                                            View Order
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                            <hr className="mb-3 mt-0 w-100" />
                                        </>
                                    ))
                                )}
                            </div>

                            <div>
                                <u className="d-flex justify-content-end font-weight-bold t-12">
                                    <Link to="/orders" onClick={() => showNotification(false)} className="cursorPointer d-inline-block">View All</Link>
                                </u>
                            </div>
                        </>
                    )}

                    {/*     <div className="mb-3">
                            <div className="sidebar_logo">
                                <Link to="/categories">
                                    <img src={Logo} className="w-100 categoryImage" />
                                </Link>
                            </div>
                            <div className="text-uppercase">Super Admin</div>
                        </div>
                        
                        <div className="mb-3 bg-light rounded row py-3">
                            <div className="col-md-3 d-flex align-items-center"><img src={User} className="userLogo w-100" /></div>
                            <div className="col-md-9 d-flex flex-row align-items-center">
                                <div className="p-1 text-wrap">
                                    <div className="font-weight-bold">Super Administrator</div>
                                    <div className="t-12">superadmin@hibee.com</div>
                                </div>
                            </div>
                        </div>

                        <div className="mb-5 overflow-auto sidebarCategoryHeight pe-2">
                            {
                                categories?.map(el => (
                                    el?.type !== "DROPDOWN"
                                    ?
                                    <div onClick={() => togglePage(el)} key={el?.id}>
                                        <div className={`py-2 px-3 rounded cursorPointer ${selectedCategory?.id === el?.id && "selectedCategory font-weight-bold shadow-sm"}`} onClick={() => togglePage(el)}>
                                            {el?.name}
                                        </div>
                                    </div>
                                    :
                                    <div className="position-relative" data-bs-toggle="collapse" data-bs-target={`#accordian-${el?.id}`} key={el?.id} onClick={() => togglePage(el)}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill={`${selectedCategory?.id !== el?.id && 'grey'}`} style={{top: "13px", right: "12px"}} className="position-absolute" viewBox="0 0 16 16">
                                            <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                                        </svg>
                                        <div className={`py-2 px-3 rounded cursorPointer ${selectedCategory?.id === el?.id && "selectedCategory font-weight-bold shadow-sm"}`} onClick={() => togglePage(el)}>
                                            {el?.name}
                                        </div>
                                        <div id={`accordian-${el?.id}`} className={`${selectedCategory?.id===el?.id && "show"}  px-3 bg-light text-sidebar-dropdown py-2 rounded t-12 accordion-collapse collapse`}>
                                            {
                                                el?.subCategory?.map(ele => (
                                                    <div className={`py-1 px-3 rounded cursorPointer`} onClick={e => {e.stopPropagation(); togglePage(el, ele, "subCategory") }}>
                                                        {ele?.name}
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>
                                ))
                            }
                        </div>

                        <div className="m-0 p-0">
                            <span className="mx-3">Help</span>
                            <span className="mx-3">About</span>
                            <span className="mx-3">Setting</span>
                        </div> */}
                </div>
            </Modal>
        </>
    );
};

export default Breadcrumb;
