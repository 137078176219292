import { createSlice } from '@reduxjs/toolkit'

export const InventoryCartSlice = createSlice({
  name: 'inventoryCart',
  initialState: { products: []},
  reducers: {
    addProducts: (state, action) => {
      return ({products: action?.payload?.products})
    },
    addInventoryProducts: (state, action) => {
      const product = action?.payload
      const cartProducts = [...state?.products]
      const i = cartProducts.findIndex(el => el._id === product._id);

      if (i > -1) cartProducts[i] = ({...product, qty: cartProducts[i]?.qty+1 })
      else cartProducts.push({...product, qty: 1 })

      return ({...state, products: cartProducts})
    },
    removeInventoryProduct: (state, action) => {
      const product = action?.payload
      let products = [...state?.products]
      const i = products.findIndex(el => el._id === product._id);

      products = [...products.slice(0, i), ...products?.slice(i+1)]
      return {...state, products }
    },
    subtractInventoryProduct: (state, action) => {
      const product = action?.payload
      let products = [...state?.products]
      const i = products.findIndex(el => el._id === product._id);

      if(products[i]?.qty === 1){
        products = [...products.slice(0, i), ...products?.slice(i+1)]
      } else {
        products = [...products.slice(0, i), {...product, qty: product?.qty - 1}, ...products?.slice(i+1)]
      }
      return {...state, products }
    }
  },
})

// Action creators are generated for each case reducer function
export const { subtractInventoryProduct, removeInventoryProduct, addInventoryProducts, addProducts } = InventoryCartSlice.actions

export default InventoryCartSlice.reducer