import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { addCategory } from "../redux/reducers/sidebarSlice";
import Logo from "../assets/images/logo.png";
import User from "../assets/images/user.png";
import { customModalStyles } from "../utils/helperFunctin";
import ReactModal from "react-modal";
import InventoryImg from "../assets/images/inventory.png";
import UserAvatarImg from "../assets/images/userAvatar.png";
import { emptyUserCart } from "../redux/reducers/userCartSlice";
import Modal from "react-modal";
import { customActionModalStyles } from "../../src/utils/helperFunctin";
import "../App.css";
import imagenotavailable from '../assets/images/not-available.jpg'
import { getTokenApi } from "../api_interface/apis";
import { FRANCHISE_ORDER, HIBEE_AUTH_URL, HIBEE_ORDER_URL, USERS } from "../api_interface/apiURLs";



function Sidebar({ containerRef }) {
  const sidebarRef = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state?.userReducer);
  const [franimage, setFranimage] = useState()
  const  [processingOrdersCount,setProcessingOrdersCount] = useState()
  const [deliveredOrdersCount,setDeliveredOrdersCount] =useState()

  const handleGetFranchise = async () => {
      const res = await getTokenApi(HIBEE_AUTH_URL + USERS);
        setFranimage(res?.data?.data?.frenchiseDetails.profile_img);
    } 
useEffect(()=>{
  handleGetFranchise()
},[])
    const getData = async () => {
      try {
        const res = await getTokenApi(
          HIBEE_ORDER_URL + FRANCHISE_ORDER + `?export=${true}&orderType=${'processing'}`
        );
        if (res?.data) {
          const orders = res.data.processingCount;
          setOrdersCount(orders);
          await updateOrdersCountFromLocalStorage();
  
          localStorage.setItem('sidebarordersCount', orders); // Store in local storage
        }
      } catch (err) {
        console.log(err);
      }
    };
  
    const maincount = localStorage.getItem('sidebarordersCount');
    const [ordersCount, setOrdersCount] = useState(maincount); // Initialize ordersCount with 0
  
    const updateOrdersCountFromLocalStorage = () => {
      const storedOrdersCount = localStorage.getItem('sidebarordersCount');
      if (storedOrdersCount !== null) {
        setOrdersCount(parseInt(storedOrdersCount, 10));
      }
    };
  
  
    
    useEffect(() => {
      const storedOrdersCount = localStorage.getItem('sidebarordersCount');
      if (storedOrdersCount !== null) {
        setOrdersCount(parseInt(storedOrdersCount, 10));
      }
  
      getData();
  
      const interval = setInterval(getData, 5000);
      return () => clearInterval(interval);
    }, []);
  
    console.log(ordersCount, 'jhjb');
  
    useEffect(() => {
      getData()
    }, [])
  const categories = [
    { id: "21", name:ordersCount >= 0 ? `Orders (${ordersCount})` : "Orders", link: "/orders" },
    // { id: "10", name: "User Listing", link: "/user-listing" },
    // { id: "12", name: "COD Active", link: "/cod-active" },
    { id: "16", name: "Inventory", link: "/inventoryscreen" },

    {
      id: "15", name: "Categories List", type: "DROPDOWN", subCategory: [
        // { id: "11", name: "Products", link: "/Inventory-Product-Listing" },
        { id: "16", name: "Categories", link: "/categories" },
      ],
    },

    // { id: "17", name: "CartPage", link: "/cart" },
    // { id: "18", name: "DataOfUsers", link: "/dataofusers" },
    { id: "20", name: "Finance", link: "/revenue" },
    { id: "19", name: "RefundOrder", link: "/ReturnOrder" },
    { id: "14", name: "Account", link: "/franchise-profile" },
  ];

  const selectedCategory = useSelector((state) => state?.sidebarReducer);
  const [sidebar, showSidebar] = useState(true);
  const [sidebarMobile, showSidebarMobile] = useState(false);
  const [modal, setModal] = useState(false);

  useEffect(() => {
    if (sidebar) {
      sidebarRef.current.style.width = "20%";
      containerRef.current.style.width = "80%";
    } else {
      sidebarRef.current.style.width = "5%";
      containerRef.current.style.width = "95%";
    }
  }, [sidebar]);

  const togglePage = (category, subCategory, type) => {
    dispatch(addCategory(category));
    if (type !== "subCategory") {
      navigate(category?.link);
    } else {
      navigate(subCategory?.link);
    }
  };

  const [actionModal, setActionModal] = useState({
    loader: false,
    show: false,
    type: "",
    data: {},
  });

  useEffect(() => {
    getData()
  }, [])
  return (
    <>
      {/* sidebar */}
      <div
        className={`${sidebar ? "p-4" : "p-0"} border-right d-none d-md-block`}
        ref={sidebarRef}
        id="sidebar"
        style={{ width: "25%" }}
      >
        {sidebar ? (
          <div className="">
            <div className="mb-4">
              <div className="h2">
                <svg
                  onClick={() => showSidebar(false)}
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-arrow-left"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"
                  />
                </svg>
              </div>
            </div>

            <div className="mb-3">
              <div className="sidebar_logo">
                <Link to="/categories">
                  <img src={Logo} className="w-100 categoryImage" />
                </Link>
              </div>
              <div className="text-uppercase">Franchise</div>
            </div>

            <div className="mb-3 bg-light rounded row py-3">
              <div className="col-md-3 d-flex align-items-center">
                {/* <img src={User} className="userLogo w-100" /> */}
                <img src={franimage } className="userLogo rounded w-100" />

              </div>
              <div className="col-md-9 d-flex flex-row align-items-center">
                <div className="p-1 text-wrap">
                  <div className="font-weight-bold text-uppercase">
                    {user && user?.name}
                  </div>
                  <div className="t-12">{user?.email}</div>
                </div>
              </div>
            </div>

            <div className="row rounded mb-3 t-12">
              <button
                className="btn btn_bg t-12 rounded btn-dark text-warning"
                onClick={() => setModal(true)}
              >
                Create new order
              </button>
            </div>

            <div className="mb-5 overflow-auto sidebarCategoryHeight pe-2">
              {categories?.map((el) =>
                el?.type !== "DROPDOWN" ? (
                  <div onClick={() => togglePage(el)} key={el?.id}>
                    <div
                      className={`py-2 px-3 rounded cursorPointer ${selectedCategory?.id === el?.id &&
                        "selectedCategory font-weight-bold shadow-sm"
                        }`}
                      onClick={() => togglePage(el)}
                    >
                      {el?.name}
                    </div>
                  </div>
                ) : (
                  <div
                    className="position-relative"
                    data-bs-toggle="collapse"
                    data-bs-target={`#accordian-${el?.id}`}
                    key={el?.id}
                    onClick={() => togglePage(el)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill={`${selectedCategory?.id !== el?.id && "grey"}`}
                      style={{ top: "13px", right: "12px" }}
                      className="position-absolute"
                      viewBox="0 0 16 16"
                    >
                      <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                    </svg>
                    <div
                      className={`py-2 px-3 rounded cursorPointer ${selectedCategory?.id === el?.id &&
                        "selectedCategory font-weight-bold shadow-sm"
                        }`}
                      onClick={() => togglePage(el)}
                    >
                      {el?.name}
                    </div>
                    <div
                      id={`accordian-${el?.id}`}
                      className={`${selectedCategory?.id === el?.id && "show"
                        }  px-3 bg-light text-sidebar-dropdown py-2 rounded t-12 accordion-collapse collapse`}
                    >
                      {el?.subCategory?.map((ele) => (
                        <div
                          className={`py-1 px-3 rounded dropdownSubMenus cursorPointer`}
                          onClick={(e) => {
                            e.stopPropagation();
                            togglePage(el, ele, "subCategory");
                          }}
                        >
                          {ele?.name}
                        </div>
                      ))}
                    </div>
                  </div>
                )
              )}
            </div>

            {/* <div className="mb-5 overflow-auto sidebarCategoryHeight pe-2">
              {categories?.map((el) =>
                el?.type !== "DROPDOWN" ? (
                  <div onClick={() => togglePage(el)} key={el?.id}>
                    <div
                      className={`py-2 px-3 rounded cursorPointer ${selectedCategory?.id === el?.id &&
                        "selectedCategory font-weight-bold shadow-sm"
                        }`}
                      onClick={() => togglePage(el)}
                    >
                      {el?.name}
                    </div>
                  </div>
                ) : (
                  <div
                    className="position-relative"
                    data-bs-toggle="collapse"
                    data-bs-target={`#accordian-${el?.id}`}
                    key={el?.id}
                    onClick={() => togglePage(el)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill={`${selectedCategory?.id !== el?.id && "grey"}`}
                      style={{ top: "13px", right: "12px" }}
                      className="position-absolute"
                      viewBox="0 0 16 16"
                    >
                      <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                    </svg>
                    <div
                      className={`py-2 px-3 rounded cursorPointer ${selectedCategory?.id === el?.id &&
                        "selectedCategory font-weight-bold shadow-sm"
                        }`}
                      onClick={() => togglePage(el)}
                    >
                      {el?.name}
                    </div>
                    <div
                      id={`accordian-${el?.id}`}
                      className={`${selectedCategory?.id === el?.id && "show"
                        }  px-3 bg-light text-sidebar-dropdown py-2 rounded t-12 accordion-collapse collapse`}
                    >
                      {el?.subCategory?.map((ele) => (
                        <div
                          className={`py-1 px-3 rounded cursorPointer`}
                          onClick={(e) => {
                            e.stopPropagation();
                            togglePage(el, ele, "subCategory");
                          }}
                        >
                          {ele?.name}
                        </div>
                      ))}
                    </div>
                  </div>
                )
              )}
            </div> */}

            <div className="m-0 p-0">

              <span className="mx-2"
                // onClick={() => dispatch({ type: "USER_LOGOUT" })}
                onClick={() => setActionModal({ show: true })}
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-box-arrow-right" viewBox="0 0 16 16">
                  <path fill-rule="evenodd" d="M10 12.5a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v2a.5.5 0 0 0 1 0v-2A1.5 1.5 0 0 0 9.5 2h-8A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-2a.5.5 0 0 0-1 0v2z" />
                  <path fill-rule="evenodd" d="M15.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L14.293 7.5H5.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3z" />
                </svg>
                <span className="mx-2 logout">Log out</span>
              </span>
            </div>
          </div>
        ) : (
          <>
            <div className="mb-4">
              <div className="h2 d-flex justify-content-center mt-4">
                <svg
                  onClick={() => showSidebar(true)}
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="mt-2 bi bi-arrow-right position-absolute"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
                  />
                </svg>
              </div>
            </div>
          </>
        )}
      </div>
      {/* sidebar mobile nav*/}
      <div
        className={`p-0 border-right d-block d-md-none`}
        style={{ width: "5%" }}
      >
        <>
          <div className="mb-4">
            <div className="h2 d-flex justify-content-center mt-4">
              <svg
                onClick={() => showSidebarMobile(true)}
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="mt-2 bi bi-arrow-right position-absolute"
                viewBox="0 0 16 16"
              >
                <path
                  fill-rule="evenodd"
                  d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
                />
              </svg>
            </div>
          </div>
        </>
      </div>
      {/* sidebar mobile */}
      {sidebarMobile && (
        <div
          className={`px-4 border-right position-absolute d-block d-md-none bg-white`}
          style={{ width: "300px", zIndex: "999", height: "100vh" }}
        >
          <div className="">
            <div className="mb-4">
              <div className="h2">
                <svg
                  onClick={() => showSidebarMobile(false)}
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-arrow-left"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"
                  />
                </svg>
              </div>
            </div>

            <div className="mb-3">
              <div className="sidebar_logo">
                <Link to="/categories">
                  <img src={Logo} className="w-100 categoryImageSidebar" />
                </Link>
              </div>
              <div className="text-uppercase">{user?.type}</div>
            </div>

            <div className="mb-3 bg-light rounded row py-3">
              <div className="col-md-3 d-flex align-items-center">
                <img src={User} className="userLogo w-100" />
              </div>
              <div className="col-md-9 d-flex flex-row align-items-center">
                <div className="p-1 text-wrap">
                  <div className="font-weight-bold text-uppercase">
                    {user?.type}
                  </div>
                  <div className="t-12">{user?.email}</div>
                </div>
              </div>
            </div>

            <div className="row rounded mb-3 t-12">
              <button
                className="btn t-12 rounded btn-dark text-warning"
                onClick={() => setModal(true)}
              >
                Create new order
              </button>
            </div>

            <div className="mb-5 overflow-auto sidebarCategoryHeight pe-2">
              {categories?.map((el) =>
                el?.type !== "DROPDOWN" ? (
                  <div onClick={() => togglePage(el)} key={el?.id}>
                    <div
                      className={`py-2 px-3 rounded cursorPointer ${selectedCategory?.id === el?.id &&
                        "selectedCategory font-weight-bold shadow-sm"
                        }`}
                      onClick={() => togglePage(el)}
                    >
                      {el?.name}
                    </div>
                  </div>
                ) : (
                  <div
                    className="position-relative"
                    data-bs-toggle="collapse"
                    data-bs-target={`#accordian-${el?.id}`}
                    key={el?.id}
                    onClick={() => togglePage(el)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill={`${selectedCategory?.id !== el?.id && "grey"}`}
                      style={{ top: "13px", right: "12px" }}
                      className="position-absolute"
                      viewBox="0 0 16 16"
                    >
                      <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                    </svg>
                    <div
                      className={`py-2 px-3 rounded cursorPointer ${selectedCategory?.id === el?.id &&
                        "selectedCategory font-weight-bold shadow-sm"
                        }`}
                      onClick={() => togglePage(el)}
                    >
                      {el?.name}
                    </div>
                    <div
                      id={`accordian-${el?.id}`}
                      className={`${selectedCategory?.id === el?.id && "show"
                        }  px-3 bg-light text-sidebar-dropdown py-2 rounded t-12 accordion-collapse collapse`}
                    >
                      {el?.subCategory?.map((ele) => (
                        <div
                          className={`py-1 px-3 rounded cursorPointer`}
                          onClick={(e) => {
                            e.stopPropagation();
                            togglePage(el, ele, "subCategory");
                          }}
                        >
                          {ele?.name}
                        </div>
                      ))}
                    </div>
                  </div>
                )
              )}
            </div>

            <div className="m-0 p-0">
              <span className="mx-2">Help</span>
              <span className="mx-2">About</span>
              <span className="mx-2">Setting</span>
              <span className="mx-2"
                // onClick={() => dispatch({ type: "USER_LOGOUT" })}

                onClick={() => {
                  showSidebarMobile(false)
                  setActionModal({ show: true })
                }}

              >
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-box-arrow-right" viewBox="0 0 16 16">
                  <path fill-rule="evenodd" d="M10 12.5a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v2a.5.5 0 0 0 1 0v-2A1.5 1.5 0 0 0 9.5 2h-8A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-2a.5.5 0 0 0-1 0v2z" />
                  <path fill-rule="evenodd" d="M15.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L14.293 7.5H5.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3z" />
                </svg>
                <span className="mx-2">Log out</span>
              </span>
            </div>
          </div>
        </div>
      )}

      <ReactModal
        isOpen={modal}
        onRequestClose={() => setModal(false)}
        style={{
          ...customModalStyles,
          content: { ...customModalStyles.content, padding: 0, width: "40vw" },
        }}
        contentLabel="Order Modal"
        ariaHideApp={false}
      >
        <div className="row px-2  ">
          <div className="col-md-6 border col-sm-12">
            <div className="d-flex px-3 py-4 flex-column align-items-center justify-content-center">
              <img
                src={UserAvatarImg} alt=""
                style={{ width: "75px", height: "75px", objectFit: "cover" }}
              />
              <h5 className="my-3">Customer Order</h5>
              <Link
                className="w-100"
                to="/user-listing"
                state={{ cart: "USER" }}
              >
                <button className="btn btn-dark w-100 text-warning" onClick={() => dispatch(emptyUserCart())}>
                  Select User
                </button>
              </Link>
            </div>
          </div>
          <div className="col-md-6 border col-sm-12 ">
            <div className="d-flex px-3 py-4 flex-column align-items-center justify-content-center">
              <img
                src={InventoryImg}
                style={{ width: "75px", height: "75px", objectFit: "cover" }}
              />
              <h5 className="my-3">Inventory Order</h5>
              <Link className="w-100" to="/Inventory-Product-Listing">
                <button className="btn btn-dark w-100 text-warning">
                  Select Product
                </button>
              </Link>
            </div>
          </div>
        </div>
      </ReactModal>

      {/* Logout Modal */}
      <Modal
        isOpen={actionModal?.show}
        onRequestClose={() => setActionModal({})}
        style={customActionModalStyles}
        contentLabel="Action Modal"
        ariaHideApp={false}

      >
        <div className=" py-3 px-3 modal_width ">
          <div className="row">
            <span className="d-flex justify-content-end cursorPointer">
              <svg
                onClick={() => setActionModal({})}
                className="d-inline"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                viewBox="0 0 16 16"
              >
                <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
              </svg>
            </span>
          </div>
          <div className="row ">
            <h4 className="text-danger d-flex justify-content-center ">
              Do you want to Logout Franchise<span></span>?
            </h4>
          </div>
          <div className="row my-5 ">
            <h5 className="d-flex justify-content-center text-uppercase">
              {user && user?.name}
            </h5>
          </div>
          <div className="form-group my-3 d-flex justify-content-center">
            <button
              disabled={actionModal?.loader}
              className="btn btn-dark me-3 mb-2 text-warning px-5"
              onClick={() => dispatch({ type: "USER_LOGOUT" })}
            >
              {actionModal?.loader ? (
                <div class="spinner-border spinner-border-sm" role="status">
                  <span class="sr-only"></span>
                </div>
              ) : (
                "Yes"
              )}
            </button>
            <button
              disabled={actionModal?.loader}
              className="btn btn-light me-3 mb-2 shadow-sm text-dark border px-5"
              onClick={() => setActionModal({})}
            >
              No
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default Sidebar;
