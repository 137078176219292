import React, { useEffect, useMemo, useState } from "react";
import { Link, useParams } from "react-router-dom";
import MainTemplate from "../../components/MainTemplate";
import "../UserListing/UserListing.css";
import ReactPaginate from "react-paginate";
import { getApi, getTokenApi, postTokenApi, putTokenApi } from "../../api_interface/apis";
import { CATEGORY_SUB_LIST_PATH, FRANCHISE_CART, FRANCHISE_INVENTORY_CART, FRANCHISE_USER_CART, HIBEE_CART_URL, HIBEE_PRODUCT_URL, PRODUCT_LIST, PRODUCT_PATH, PRODUCT_SUBCATEGORY } from "../../api_interface/apiURLs";
import { useDispatch, useSelector } from "react-redux";
import { addUserProducts, removeUserProduct } from "../../redux/reducers/userCartSlice";
import { addInventoryProducts, removeInventoryProduct } from "../../redux/reducers/InventoryCartSlice";
import { debounce } from "../../utils/helperFunctin";
import ProductList from "./ProductList";


function InventoryProductListing() {
  const dispatch = useDispatch()
  const cartProducts = useSelector(state => state?.inventoryCartReducer?.products)
  const franchise = useSelector((state) => state?.userReducer);
  const franchiseId = franchise?._id
  const { subCategoryId } = useParams()
  const [modal, setModal] = useState(false)
  const [loader, setLoader] = useState(false)
  const [itemOffset, setItemOffset] = useState(0);
  const [productList, setProductList] = useState([]);
  const [subcatogory, setSubCatogory] = useState([])
  const [query, setQuery] = useState("")



  const itemsPerPage = 10;
  const endOffset = itemOffset + itemsPerPage;
  const pageCount = Math.ceil(productList.length / itemsPerPage);
  const currentProductList = productList.slice(itemOffset, endOffset);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % productList.length;
    setItemOffset(newOffset);
  };

  const syncCart = useMemo(() => debounce(async (products) => {
    if (franchiseId && products?.length) {
      const data = products.map(el => ({ productId: el?._id, qty: el?.qty }))
      const res = await putTokenApi(HIBEE_CART_URL + FRANCHISE_CART + FRANCHISE_INVENTORY_CART, { products: data })

    }
  }, 500), [])

  useEffect(() => { syncCart(cartProducts) }, [cartProducts])
  // console.log(subCategoryId,"knkn");
  const getCategories = async (search) => {
    let res
    if (subCategoryId) {
      res = await postTokenApi(HIBEE_PRODUCT_URL + PRODUCT_PATH + PRODUCT_SUBCATEGORY + `?sort=''&search=${query}&archive=''`, { subcategory: subCategoryId });
      setProductList(res?.data?.data?.reverse());
    } else {
      res = await getApi(HIBEE_PRODUCT_URL + PRODUCT_LIST + `?sort=''&search=${query}&archive=''`);
    }
    if (res.status == 200) {
      setProductList(res?.data?.data?.reverse());
      setLoader(false);
    }
  };
  console.log(ProductList)
  const getSortbyCategories = async () => {
    setLoader(true)
    const subcatId = productList?.subcategory
    const res = await getApi(HIBEE_PRODUCT_URL + PRODUCT_LIST)
  }
  useEffect(() => {
    getSortbyCategories(query)
  }, [query])
  useEffect(() => {
    getCategories(query);
  }, [query]);
  const handleAddInventoryDisPatch = (el) => {
    dispatch(addInventoryProducts(el))
  }

  const handleRemoveInventoryDispatch = (el) => {
    dispatch(removeInventoryProduct(el))
  }

  return (
    <MainTemplate categoryName="Inventory Product Listing" categoryList={["Franchise", "Inventory Product Listing"]} >
      <div className="row mb-3 rounded p-2 bg-white">
        {/* <div className="col-sm-2 my-3">
          <select className=" t-12 form-select" aria-label="Default select example" outline="none">
            <option selected>Sort By</option>
            <option value="1">One</option>
            <option value="2">Two</option>
            <option value="3">Three</option>
          </select>
        </div> */}
        {/* <div className="col-sm-2 my-3 ">
          <select className=" t-12 form-select" aria-label="Default select example" outline="none">
            <option selected>Categories</option>
            <option value="1">One</option>
            <option value="2">Two</option>
            <option value="3">Three</option>
          </select>
        </div> */}
        <div className="col-sm-6 my-3">
          <input type="text" name="query" className="t-12 form-control" placeholder="Search" value={query} onChange={e => setQuery(e.target.value)} />
        </div>
        <div className="col-sm-6 my-3 d-flex justify-content-end">
          <Link to={franchise?._id ? `/Inventory-cart` : "/user-listing"}>
            <button type="button" className="t-12 btn btn-dark text-warning px-3"> {franchise?._id ? "Go to cart" : "Select User"}</button>
          </Link>
        </div>
      </div>

      {
        loader
          ?
          <div className="text-center mt-5" >
            <button class="btn mt-5" style={{ backgroundColor: '#FFEDC2' }} type="button">
              <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>{" "}
              Loading...
            </button>
          </div>
          :
          <div className="row rounded px-3 bg-white">
            {/* header */}
            <div className="row m-0 ">
              {/* <div className="font-weight-bold t-12 tFext-uppercase my-3 col-md-1">
                ID
              </div> */}
              <div className="font-weight-bold t-12 text-uppercase my-3 col-md-1">
                Photo
              </div>
              <div className="font-weight-bold t-12 text-uppercase my-3 col-md-2">
                Product
              </div>
              <div className="font-weight-bold t-12 text-uppercase p-0 my-3 col-md-2">
                Price
              </div>
              <div className="font-weight-bold t-12 text-uppercase p-0 my-3 col-md-1 mx-2">
                Size
              </div>
              <div className="font-weight-bold t-12 text-uppercase p-0 my-3 col-md-1"></div>
            </div>

            <div className="m-0 p-0 pe-3 overflow-auto caterogyPageScroll">
              {currentProductList?.map((el, index) => (
                <div
                  className="row border rounded m-0 px-2  mb-2 d-flex align-items-center"
                  key={el?._id}
                >
                  
                  <div className="t-12 text-uppercase my-3 col-md-1">
                  {el?.sizeList.quantity === 0 ? (
                    <div className="categoryImageDiv shadow-sm  rounded p-1">
                      {el?.images && el.images.length > 0 ? (
                        <img   src={el.images[0].image} alt="loading" className="categoryImage h-100 rounded " />
                      ) : (
                        <img   src={el?.image} alt="loading" className="categoryImage h-100 rounded " />
                      )}
                    </div>
                     ) : (
                      <div className="categoryImageDiv shadow-sm  rounded p-1">
                      {el?.images && el.images.length > 0 ? (
                        <img  src={el.images[0].image} alt="loading" className="categoryImage h-100 rounded " />
                      ) : (
                        <img  src={el?.image} alt="loading" className="categoryImage h-100 rounded " />
                      )}
                    </div>)}
                  </div>
                  <div className="t-12 text-uppercase my-3 col-md-2 d-flex px-4">
                    {el?.product_name}
                  </div>
                  <div className="t-12 text-uppercase my-3 col-md-2">
                  {'₹'} {el?.price}
                  </div>
                  <div className="t-12 text-uppercase my-3 col-md-2">
                    {el?.sizeList?.size}{el?.sizeList?.unit}
                  </div>

                  <div className="t-12 text-uppercase my-3 row justify-content-end col-md-4">
                    {el.sizeList.quantity === 0 ? (
                      <button className="addbtn text-end w-50 text-bg-dark" disabled>
                        Out of Stock
                      </button>
                    ) : (
                      cartProducts?.find((ele) => ele?._id === el?._id && ele?.qty > 0) ? (
                        <button
                          className="addbtn text-end w-50"
                          onClick={() => handleRemoveInventoryDispatch(el)}
                        >
                          Remove
                        </button>
                      ) : (
                        <button
                          className="addbtn text-end w-50"
                          onClick={() => handleAddInventoryDisPatch(el)}
                        >
                          Add
                        </button>
                      )
                    )}
                  </div>


                </div>
              ))}
            </div>

            <div className="row m-2 d-flex justify-content-center align-items-center">
              <ReactPaginate
                breakLabel="..."
                nextLabel=">"
                onPageChange={handlePageClick}
                pageRangeDisplayed={3}
                pageCount={pageCount}
                previousLabel="<"
                renderOnZeroPageCount={null}
                pageClassName="page-item mb-0"
                subContainerClassName="pages "
                pageLinkClassName="page-link"
                previousClassName="page-item"
                previousLinkClassName="page-link"
                nextClassName="page-item"
                nextLinkClassName="page-link"
                breakClassName="page-item"
                breakLinkClassName="page-link"
                marginPagesDisplayed={2}
                containerClassName="pagination"
                activeClassName="active"
              />
            </div>


          </div>
      }


    </MainTemplate>
  );
}

export default InventoryProductListing;
