import { configureStore } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import userReducer from './reducers/userSlice';
import sidebarReducer from './reducers/sidebarSlice';
import userCartReducer from './reducers/userCartSlice';
import inventoryCartReducer from './reducers/InventoryCartSlice';
import notificationReducer from './reducers/notificationslice';
import { persistStore } from 'redux-persist';

const appReducer = combineReducers({
  userReducer,
  sidebarReducer,
  userCartReducer,
  inventoryCartReducer,
  notificationReducer,
});

const rootReducer = (state, action) => {
  if (action.type === 'USER_LOGOUT') {
    storage.removeItem('persist:root');
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['notificationReducer'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  // Add middleware here if needed
});

export const persistor = persistStore(store);

export default store;
