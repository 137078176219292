import {
  Form,
  Button,
  FormGroup,
  FormControl,
  ControlLabel,
} from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import React from "react";
import './NewFranchise.css'
import placeholder from '../../../assets/images/placeholder.png'
import MainTemplate from "../../../components/MainTemplate";

function App() {
  return (
    <MainTemplate categoryName="Franchise" categoryList={["Admin", "Franchise"]}>
      <div>
      <div className="container containerbg overflow-auto rounded bg-white row w-100 ">
        <div className="row col-md-12 rowhight"> </div>
        <div className="row">
          <div className="col-md-1"></div>
          <div className="col-md-5">
            <Form>
              <Row>
                <div className="col-md-4 unique">
                  <Form.Group className="mb-3 code" controlId="Unique Code">
                    <Form.Label>Unique Code</Form.Label>
                    <Form.Control type="number" placeholder="#66778" />
                  </Form.Group>
                </div>
                <div></div>
              </Row>

              {/* <img src={placeholder} /> */}
              
              <Form.Group className="mb-3" controlId="Owner Name">
                <Form.Label>Owner Name</Form.Label>
                <Form.Control className="Textbar" type="text" placeholder="Type your name hear" />
              </Form.Group>

              <Form.Group className="mb-3" controlId="Phone Number">
                <Form.Label>Phone Number</Form.Label>
                <Form.Control
                className="Textbar"
                  type="number"
                  placeholder="type your Phone number hear"
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="Email ID">
                <Form.Label>Email ID</Form.Label>
                <Form.Control
                className="Textbar"
                  type="email"
                  placeholder="type your Email Id hear"
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="Society/Community">
                <Form.Label>Society/Community</Form.Label>
                <Form.Control
                className="Textbar"
                  type="text"
                  placeholder="type your Society/Community hear"
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="Address 1">
                <Form.Label>Address line 1</Form.Label>
                <Form.Control className="Textbar" type="text" placeholder="Type your Address" />
              </Form.Group>

              <Form.Group className="mb-3" controlId="Address 2">
                <Form.Label>Address line 2</Form.Label>
                <Form.Control className="Textbar" type="text" placeholder="Type your Address" />
              </Form.Group>

              <Row>
                <div className="col-md-6">
                  <Form.Group className="mb-3" controlId="State">
                    <Form.Label>State</Form.Label>
                    <Form.Control
                    className="smtext"
                      type="text"
                      placeholder="type your State hear"
                    />
                  </Form.Group>
                </div>
                <div className="col-md-6 ">
                  <Form.Group className="mb-3" controlId="City">
                    <Form.Label className="margintext">City</Form.Label>
                    <Form.Control
                    className="smtext margin"
                      type="text"
                      placeholder="type your City hear"
                    />
                  </Form.Group>
                </div>
              </Row>

              <Row>
                <div className="col-md-6">
                  <Form.Group className="mb-3" controlId="Pin code">
                    <Form.Label>Pin code</Form.Label>
                    <Form.Control
                    className="smtext"
                      type="number"
                      placeholder="type your Pin code hear"
                    />
                  </Form.Group>
                </div>
                <div></div>
              </Row>

              <Form.Group className="mb-3" controlId="Aadhar card">
                <Form.Label>Aadhar card</Form.Label>
                <textarea
                  className="form-control center pt-5 box"
                  id="Aadhar card"
                  rows="4"
                  placeholder="Upload you are Aadhar card hear"
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="Pan Card">
                <Form.Label>Pan Card</Form.Label>
                <textarea
                  className="form-control center pt-5 box"
                  id="Pan Card"
                  rows="4"
                  placeholder="Upload you are pan card hear"
                />
              </Form.Group>

              <Form.Group className="mb-3 Textbar" controlId="Reffered by">
                <Form.Label>Reffered by</Form.Label>
                <Form.Control className="Textbar" type="text" placeholder="Reffered by" />
              </Form.Group>

              <div className="row" id="row"></div>

              <div className="mb-3">
                <Button variant="primary" size="lg" id="btnRegister">
                Register
                </Button>
              </div>
            </Form>
          </div>

          <div className="col-md-6">
            <div className="row">
              <div className="col-md-3"></div>
              <div className="col-md-6">
                <Form.Control
                  className="Uploadimg mt-5 center"
                  type="text"
                  placeholder="Upload Photo"
                />
              </div>
              <div className="col-md-3"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
		</MainTemplate>
    
  );
}

export default App;
