import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
// import CategoryList from "../CategoryList/CategoryList";
// import "./LandingPage.css";
import Logo from "../assets/images/logo.png";
import User from "../assets/images/user.png";
import { addCategory } from "../redux/reducers/sidebarSlice";
import Breadcrumb from "./Breadcrumb";
import Sidebar from "./Sidebar";

function MainTemplate({ children, categoryName, categoryList }) {

  const containerRef = useRef(null)

  return (
    <div className="parentContainer row h-100 m-0">
      {/* sidebar */}
      {/* <div
        className="p-4 border-right"
        ref={sidebarRef}
        id="sidebar"
        style={{ width: "25%" }}
      >
        {sidebar ? (
          <>
            <div className="mb-4">
              <div className="h2">
                <svg
                  onClick={() => showSidebar(false)}
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-arrow-left"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"
                  />
                </svg>
              </div>
            </div>

            <div className="mb-3">
              <div className="sidebar_logo">
                <Link to="/categories">
                  <img src={Logo} className="w-100 categoryImage" />
                </Link>
              </div>
              <div className="text-uppercase">{selectedUser?.userReducer?.type}</div>
            </div>

            <div className="mb-3 bg-light rounded row py-3">
              <div className="col-md-3 d-flex align-items-center">
                <img src={User} className="userLogo w-100" />
              </div>
              <div className="col-md-9 d-flex flex-row align-items-center">
                <div className="p-1 text-wrap">
                  <div className="font-weight-bold">{selectedUser?.userReducer?.name}</div>
                  <div className="t-12">{selectedUser?.userReducer?.email}</div>
                </div>
              </div>
            </div>

            <div className="mb-5 overflow-auto sidebarCategoryHeight pe-2">
              {categories?.map((el) =>
                el?.type !== "DROPDOWN" ? (
                  <div onClick={() => togglePage(el)} key={el?.id}>
                    <div
                      className={`py-2 px-3 rounded cursorPointer ${selectedCategory?.name === el?.name &&
                        "selectedCategory font-weight-bold shadow-sm"
                        }`}
                      onClick={() => togglePage(el)}
                    >
                      {el?.name}
                    </div>
                  </div>
                ) : (
                  <div
                    className="position-relative"
                    data-bs-toggle="collapse"
                    data-bs-target={`#accordian-${el?.id}`}
                    key={el?.id}
                    onClick={() => togglePage(el)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      style={{ top: "13px", right: "12px" }}
                      className="position-absolute"
                      viewBox="0 0 16 16"
                    >
                      <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                    </svg>
                    <div
                      className={`py-2 px-3 rounded cursorPointer ${selectedCategory?.name === el?.name &&
                        "selectedCategory font-weight-bold shadow-sm"
                        }`}
                      onClick={() => togglePage(el)}
                    >
                      {el?.name}
                    </div>
                    <div
                      id={`accordian-${el?.id}`}
                      className={`${selectedCategory?.id === el?.id && "show"
                        }  px-3 bg-light text-sidebar-dropdown py-2 rounded t-12 accordion-collapse collapse`}
                    >
                      {el?.subCategory?.map((ele) => (
                        <div
                          className={`py-1 px-3 rounded cursorPointer`}
                          onClick={(e) => {
                            e.stopPropagation();
                            togglePage(el, ele, "subCategory");
                          }}
                        >
                          {ele?.name}
                        </div>
                      ))}
                    </div>
                  </div>
                )
              )}
            </div>

            <div className="m-0 p-0">
              <span className="mx-3">Help</span>
              <span className="mx-3">About</span>
              <span className="mx-3">Setting</span>
            </div>
          </>
        ) : (
          <>
            <div className="mb-4">
              <div className="h2">
                <svg
                  onClick={() => showSidebar(true)}
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-arrow-right"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
                  />
                </svg>
              </div>
            </div>
          </>
        )}
      </div> */}
      <Sidebar containerRef={containerRef} />

      {/* main container */}
      <div
        className="p-0 bg-light"
        id="mainContainer"
        ref={containerRef}
        style={{ width: "75%" }}
      >
        <div className="mx-3 my-4 px-3">
          <div className="mt-4">
            <Breadcrumb name={categoryName} list={categoryList} />

            {children}
          </div>    
        </div>
      </div>
    </div>
  );
}

export default MainTemplate;
