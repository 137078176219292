import React, { useEffect, useState } from "react";
import "../FranchiseProfile/FranchiseProfile.css";
import MainTemplate from "../../components/MainTemplate";
import profile from "../../assets/images/profile.png";
import { CHANGE_PASSWORD, FRANCHISE_PROFILE, HIBEE_AUTH_URL, HIBEE_PRODUCT_URL, SOCIETY, UPDATE_FRANCHISE_API_PATH, USERS } from "../../api_interface/apiURLs";
import { getApi, getTokenApi, putApi, putTokenApi } from "../../api_interface/apis";
import { Button, Form, Row, Spinner } from "react-bootstrap";
import placeholder from '../../assets/images/placeholder.png'
import { useSelector } from "react-redux";
import Modal from 'react-modal';
import { toast } from "react-toastify";
import { Tabs, Tab } from 'react-bootstrap';
import { checkPasswordValidity, customActionModalStyles, customModalStyles } from "../../utils/helperFunctin";

function FranchiseProfile() {
  const [franchiseProfileList, setFranchiseProfileList] = useState([])
  const [actionModal, setActionModal] = useState({ loader: false, show: false, type: "", data: {} })
  const [modal, setModal] = useState(false)
  const [Modall, setModall] = useState()
  const [loader, setLoader] = useState(false)
  const [aadharImage, setAadharImage] = useState('')
  const [panImage, setpanImage] = useState('')
  const [profileImage, setProfileImage] = useState('')
  const [filterOutSociety, setFilterOutSociety] = useState([])
  const frachiseSocietyID = franchiseProfileList?.society;



  const [franchiseData, setFranchiseData] = useState({
    name: '',
    number: '',
    email: '',
    password: '',
    society: '',
    flatNumber: '',
    address2: '',
    state: '',
    city: '',
    pincode: '',
    aadhar: '',
    pan: '',
    cod_status: false,
    referred_by: '',
    profile: "",
    _id: "",
    bank_name: "",
    account_holder_name: "",
    account_number: "",
    ifsc_code: "",
    branch_name: "",
    account_type: "",
    password: ""
  })

  const [franchiseDataError, setFranchiseDataError] = useState({
    name: '',
    number: '',
    email: '',
    password: '',
    society: '',
    flatNumber: '',
    address2: '',
    state: '',
    city: '',
    pincode: '',
    aadhar: '',
    pan: '',
    cod_status: false,
    referred_by: '',
    profile: '',
    _id: "",
    bank_name: "",
    account_holder_name: "",
    account_number: "",
    ifsc_code: "",
    bank_branch: "",
    account_type: "",
    pan_number: "",
    adhar_number: "",

  })

  const user = useSelector((state) => state.userReducer);
  const handleReeUploadFile = (ele) => {
    document.getElementById(ele).click()
  }

  /** haandle change **/
  const handleFranchisechange = (event) => {
    if (event.target.name != 'aadhar' && event.target.name != 'pan' && event.target.name != 'profile') {
      setFranchiseData({
        ...franchiseData, [event.target.name]: event.target.value
      })
      setFranchiseDataError({
        ...franchiseDataError, [event.target.name]: null
      })
    }

    if (event.target.name == 'aadhar') {
      var url = URL.createObjectURL(event.target.files[0]);
      setAadharImage(url)
      setFranchiseData({
        ...franchiseData, [event.target.name]: event.target.files[0]
      })
      setFranchiseDataError({
        ...franchiseDataError, [event.target.name]: null
      })

    }
    if (event.target.name == 'pan') {
      var url = URL.createObjectURL(event.target.files[0]);
      setpanImage(url)
      setFranchiseData({
        ...franchiseData, [event.target.name]: event.target.files[0]
      })
      setFranchiseDataError({
        ...franchiseDataError, [event.target.name]: null
      })
    }
    if (event.target.name == 'profile') {
      var url = URL.createObjectURL(event.target.files[0]);
      setProfileImage(url)
      setFranchiseData({
        ...franchiseData, [event.target.name]: event.target.files[0]
      })
      setFranchiseDataError({
        ...franchiseDataError, [event.target.name]: null
      })
    }
  }

  const handleEditClick = (ele) => {
    setFranchiseData(ele)
    setFranchiseData({
      ...franchiseData,
      name: ele.owner_name,
      number: ele.phone_number,
      email: ele.email,
      password: '',
      society: ele.society,
      flatNumber: ele.flatNumber,
      address2: '',
      aadhar: ele.adhar_img,
      pan: ele.pan_img,
      cod_status: ele?.cod_status,
      referred_by: ele.referred_by,
      profile: ele.profile_img,
      _id: ele._id,
      bank_name: ele.bank_name,
      account_holder_name: ele.account_holder_name,
      account_number: ele.account_number,
      ifsc_code: ele.ifsc_code,
      account_type: ele.account_type,
      pan_number: ele.pan_number,
      adhar_number: ele.adhar_number,

    })
    setModal(true);
  }
  const [changePassword, setChanePassword] = useState({ currentPassword: '', newPassword: '', confirmPassword: "", loader: false })
  const [changePasswordError, setChanePasswordError] = useState({ currentPassword: '', newPassword: '', confirmPassword: "", loader: false })
  const handleChange = (ele) => {
    setChanePassword({ ...changePassword, [ele.target.name]: ele.target.value })
    setChanePasswordError({ ...changePasswordError, [ele.target.name]: null })
  }


  /** handle Submit **/
  const handleSubmitFranchise = async (ele) => {
    ele.preventDefault()
    setLoader(true)
    const { name, number, email, password, society, address1, flatNumber, cod_status, city, pincode, aadhar, pan, referred_by, profile, _id, bank_name, account_holder_name, account_number, account_type, bank_branch, ifsc_code, adhar_number, pan_number } = franchiseData
    const formData = new FormData()
    formData.append("owner_name", name)
    formData.append("phone_number", number)
    formData.append("email", email)
    // formData.append("password", password)
    // formData.append("flatNumber", flatNumber)
    formData.append("address", JSON.stringify({ street: address1, city: city, zip: pincode }))
    formData.append("adhar_img", aadhar)
    formData.append("pan_img", pan)
    formData.append("profile_img", profile)
    formData.append("cod_status", cod_status)
    formData.append("referred_by", referred_by)
    formData.append('_id', _id)
    formData.append('bank_name', bank_name)
    formData.append('account_holder_name', account_holder_name)
    formData.append('account_number', account_number)
    formData.append('ifsc_code', ifsc_code)
    formData.append('bank_branch', bank_branch)
    formData.append('account_type', account_type)
    formData.append('adhar_number', adhar_number)
    formData.append('pan_number', pan_number)

    const res = await putTokenApi(HIBEE_PRODUCT_URL + UPDATE_FRANCHISE_API_PATH, formData, { "Content-Type": "multipart/form-data" })
    if (res.data.error == false) {
      toast.success(res.data.message, { position: "top-center", autoClose: 2000 });
      setLoader(false)
      setModal(false)
      handleGetFranchiseProfile()
    } else {
      toast.error(res.data.message, { position: "top-center", autoClose: 2000 });
      setLoader(false)
      setModal(false)
      handleGetFranchiseProfile()
    }
  }



  /** Get Profile Detailes **/
  const handleGetFranchiseProfile = async () => {
    setLoader(true);
    try {
      const res = await getTokenApi(HIBEE_AUTH_URL + USERS);
      if (res.status === 200) {
        setFranchiseProfileList(res?.data?.data?.frenchiseDetails);
        setLoader(false);
      }
    } catch (error) {
      console.log(error);
      setLoader(false);
    }
  };

  console.log(franchiseProfileList, "franchiseProfileList");

  //get socitey address

  const socitey = async () => {
    setLoader(true);
    const response = await getTokenApi(HIBEE_PRODUCT_URL + SOCIETY);
    const sociteyData = response?.data?.data;

    if (sociteyData && sociteyData.length > 0) {
      const filterSociety = sociteyData.find(society => society._id === frachiseSocietyID);

      if (filterSociety) {
        setFilterOutSociety(filterSociety);
      } else {
        console.log("No society found with the provided ID");
      }
    } else {
      console.log("No society data available");
    }
    console.log(filterOutSociety)
  }
  // toggel profile table 
  const [activeTab, setActiveTab] = useState(0);

  const handleTabClick = (index) => {
    setActiveTab(index);
  };


  const handlaValidation = () => {
    const newErrors = {}
    const { currentPassword, newPassword, confirmPassword } = changePassword
    if (!currentPassword) newErrors.currentPassword = 'Please enter current password'
    // else if (!checkPasswordValidity(currentPassword)) newErrors.confirmPassword = 'password && confirm password should be same'
    if (!newPassword) newErrors.newPassword = 'Please enter new password'
    else if (!checkPasswordValidity(newPassword)) newErrors.confirmPassword = 'Password must contain min 8 letter, 1 special character, 1 upper and 1 lower case and a number'
    if (!confirmPassword) newErrors.confirmPassword = 'Please Re-enter confirm password'
    // else if (!checkPasswordValidity(currentPassword))  = 'Password must contain min 8 letter, 1 special character, 1 upper and 1 lower case and a number'
    else if (newPassword != confirmPassword) newErrors.confirmPassword = 'password && confirm password should be same'
    return newErrors
  }


  const handleSubmit = async (e) => {
    e.preventDefault()
    try {
      setChanePassword({ ...changePassword, loader: true })
      const handlaValidationObject = handlaValidation()
      if (Object.keys(handlaValidationObject).length > 0) {
        setChanePasswordError(handlaValidationObject)
        setChanePassword({ ...changePassword, loader: false })

      } else {
        const payload = {
          old_password: changePassword?.currentPassword,
          new_password: changePassword?.confirmPassword,
          adminId: franchiseProfileList?.user_id
        }
        const responce = await putTokenApi(HIBEE_AUTH_URL + CHANGE_PASSWORD, payload)
        if (responce.status == 200) {
          console.log(responce, 'responce');
          toast.success(responce?.data?.message, { position: "top-center", autoClose: 2000 });
          handleClear()
        }
      }
    } catch (err) {
      const message = err?.response?.data?.message || "Something went wrong";
      toast.error(message, { position: "top-center", autoClose: 2000 });
      handleClear()
    }
  }




  const handleClear = () => {
    setChanePassword({
      ...changePassword,
      currentPassword: '',
      newPassword: '',
      confirmPassword: "",
      loader: false
    })
    setChanePasswordError({
      ...changePasswordError,
      currentPassword: '',
      newPassword: '',
      confirmPassword: "",
      loader: false

    })
    setModal(false)
  }

  useEffect(() => {
    handleGetFranchiseProfile()
  }, [])

  useEffect(() => {
    socitey()
  }, [])

  const handleCloseModal = () => {
    setModal(false)
    setFranchiseData({})
    setLoader(false)
    setAadharImage('')
    setpanImage('')
    setProfileImage('')
  }


  // console.log(franchiseProfileList,'franchiseProfileList999999999999');

  return (
    <MainTemplate categoryName="Account" categoryList={["Account"]}>
      <>
        {
          loader == false && franchiseProfileList && [franchiseProfileList]?.map((ele) => {
            return <div>
              <div className="row mb-4  bg-white   m-0 p-0 pe-3 overflow-auto rounded  ">
                <div className="col-4  mt-5 mb-4 margin-adusting ">
                  <div className="ims-centerstyles bg-light">
                    <div className="border rounded mb-3 ">
                      <img className="rounded profile-image " src={ele?.profile_img} />
                    </div>
                    <div className="form-group pb-1">
                      <label className="dark-name">Name</label>
                      <p className="fant-name">{ele?.owner_name}</p>
                    </div>

                    <div className="form-group pb-1">
                      {/* <label className="dark-name">COD</label> */}
                      <div className="col-4">
                        {/* <label className="switch">
                          <input className="switch-input" type="checkbox"
                            name='cod_status'
                            checked={ele?.cod_status}
                            onChange={(e) => setFranchiseData({ ...franchiseData, cod_status: franchiseData?.cod_status == false ? true : false })}
                          />
                          <span className="switch-label"></span>
                          <span className="switch-handle"></span>
                        </label> */}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 mb-4 ">
                  <div className="tabs">
                    <div className="tab-header d-flex p-2">
                      <div
                        className={`mx-3 m-2  tab-item nav-link ${activeTab === 0 ? "activev" : ""}`}
                        onClick={() => handleTabClick(0)} >
                        Personal Info
                      </div>
                      <div
                        className={`mx-3 m-2  tab-item nav-link ${activeTab === 1 ? "activev" : ""}`}
                        onClick={() => handleTabClick(1)}
                      >
                        Bank Details
                      </div>

                    </div>
                    <div className="tab-content">
                      {activeTab === 0 && (
                        <div className="tab-pane fade show active bg-transparent" id="personalinfo" role="tabpanel" aria-labelledby="home-tab">



                          <div className="row">
                            <div className="col-lg-6 border-start ">
                              <div className="form-group mt-5 pb-2 ms-2">
                                <label className="dark-name">Email</label>
                                <p className="fant-name"><u>{ele?.email}</u></p>
                              </div>
                              <div className="form-group pb-2 ms-2">
                                <label className="dark-name">Phone Number</label>
                                <p className="fant-name"><u>{ele?.phone_number}</u></p>
                              </div>
                              <div className="form-group pb-1 addrressize ms-2">
                                <label className="dark-name">Flat Number</label>
                                <p className="addrs-styles">
                                  {ele?.flatNumber}
                                </p>
                              </div>
                              <div className="form-group pb-1 addrressize ms-2">
                                <label className="dark-name">Tower Name</label>
                                <p className="addrs-styles">
                                  {ele?.tower?.towerName}
                                </p>
                              </div>

                              <div className="form-group mt-4 pb-1 ms-2">
                                <label className="dark-name">Society</label>
                                <p className="fant-name">{ele?.society?.societyName}</p>
                              </div>
                              {/* <div className="form-group mt-1 pb-2">
                                <label className="dark-name">Reffrd By</label>
                                <p className="fant-name">{ele?.referred_by}</p>
                              </div> */}
                            </div>
                            <div className="col-lg-6 border-start p-2 " >
                              <div className="form-group mt-4 pb-2 ms-lg-4">
                                <label className="dark-name">Aadhar card</label>
                                <p className="fant-name">{ele?.adhar_number}</p>
                                <img alt="" src={ele?.adhar_img} width='300px' height='200px' />
                              </div>
                              <div className="form-group pb-2 ms-lg-4">
                                <label className="dark-name">PAN Card</label>
                                <p className="fant-name">{ele?.pan_number}</p>
                                <img alt="" src={ele?.pan_img} width='300px' height='200px' />
                              </div>
                              <div className="form-group pb-2 ms-lg-4">
                                <h6 className="fw-bold text-danger">Update Password</h6>
                                <button
                                  type="button"
                                  className="t-12 w-50 btn btn-outline-dark"
                                  onClick={() => {
                                    setModall(true);
                                  }}
                                >
                                  Change Password
                                </button>
                              </div>

                            </div>
                            <Modal
                              isOpen={Modall}
                              onRequestClose={() => setModall(false)}
                              style={customModalStyles}
                              contentLabel="Example Modal"
                              ariaHideApp={false}
                            >
                              <div className="px-5 py-3">
                                <div className="row">
                                  <span className="d-flex justify-content-end cursorPointer">
                                    <svg
                                      onClick={() => {
                                        setModall(false)
                                        handleClear()
                                      }}
                                      className="d-inline"
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="16"
                                      height="16"
                                      fill="currentColor"
                                      viewBox="0 0 16 16"
                                    >
                                      <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
                                    </svg>
                                  </span>
                                </div>
                                <div className="modalMaxHeight">
                                  <h5 className="text-center" >Change Password</h5>
                                  <div className="row">
                                    <div className="col-6 d-flex align-items-center">
                                      <span>Current Password <span className="text-danger" >*</span></span>
                                    </div>
                                    <div className="col-6">
                                      <input
                                        className={`placeholder-light form-control my-1 py-2 px-3 ${changePasswordError.currentPassword ? 'border border-danger' : ''}`}
                                        type="password"
                                        placeholder="Please enter current password"
                                        name='currentPassword'
                                        value={changePassword.currentPassword}
                                        onChange={handleChange}
                                      />
                                      <span className="text-danger"  >{changePasswordError.currentPassword}</span>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-6 d-flex align-items-center">
                                      <span  >New Password  <span className="text-danger" >*</span></span>
                                    </div>
                                    <div className="col-6">
                                      <input
                                        className={`placeholder-light form-control my-1 py-2 px-3 ${changePasswordError.newPassword ? 'border border-danger' : ''}`}
                                        type="password"
                                        placeholder="Please enter new password"
                                        name='newPassword'
                                        value={changePassword.newPassword}
                                        onChange={handleChange}
                                      />
                                      <span className="text-danger">{changePasswordError.newPassword}</span>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-6 d-flex align-items-center">
                                      <span >Confirm New Password <span className="text-danger" >*</span></span>
                                    </div>
                                    <div className="col-6">
                                      <input
                                        className={`placeholder-light form-control my-1 py-2 px-3 ${changePasswordError.confirmPassword ? 'border border-danger' : ''}`}
                                        type="password"
                                        placeholder="Please Re-enter confirm password"
                                        name='confirmPassword'
                                        value={changePassword.confirmPassword}
                                        onChange={handleChange}
                                      />
                                      <span className="text-danger" >{changePasswordError.confirmPassword}</span>
                                    </div>
                                  </div>
                                </div>

                                <div className="row" >
                                  <div className="col-md-3 form-group my-3">
                                    <button className="btn btn_bg btn-dark text-warning px-5"
                                      onClick={handleSubmit}
                                    >
                                      Submit
                                      {
                                        changePassword.loader
                                          ?
                                          <Spinner
                                            animation="border"
                                            variant="light"
                                            size="sm"
                                          />
                                          :
                                          ''
                                      }
                                    </button>
                                  </div>
                                  <div className="col-md-3 form-group my-3" style={{ marginLeft: '10px' }}>
                                    <button className="btn btn_bg  btn-dark text-warning px-5"
                                      onClick={handleClear}
                                    >Clear</button>
                                  </div>
                                </div>
                              </div>
                            </Modal>
                            <div>
                            </div>

                            <div className="p-2 m-1 bg-light">
                              <ul className="d-flex align-items-center ">

                                <li className="text-danger list-unstyled d-flex align-items-center">* For updating the personal details contact to ADMIN or SUPERADMIN</li>
                              </ul>

                            </div>

                          </div>

                        </div>
                      )}
                      {activeTab === 1 && (
                        <div className="mt-3 p-3">
                          <div className=" border-b-dashed mt-4 m-2 d-flex justify-content-between">
                            <p className="fs-6 fw-bolder text-secondary" >BANK NAME
                            </p>
                            <p className="fw-bolder">{ele?.bank_name}</p>

                          </div>
                          <div className=" border-b-dashed mt-4 m-2 d-flex justify-content-between">
                            <p className="fs-6 fw-bolder text-secondary" >ACCOUNT HOLDER’S NAME
                            </p>
                            <p className="fw-bolder">{ele?.account_holder_name}</p>

                          </div>
                          <div className="border-b-dashed mt-4 m-2 d-flex justify-content-between">
                            <p className="fs-6 fw-bolder text-secondary" >BANK ACCOUNT NUMBER
                            </p>
                            <p className="fw-bolder">{ele?.account_number}</p>

                          </div>
                          <div className=" border-b-dashed mt-4 m-2 d-flex justify-content-between">
                            <p className="fs-6 fw-bolder text-secondary " >IFSC CODE </p>
                            <p className="fw-bolder">{ele?.ifsc_code}</p>

                          </div>
                          {/* <div className=" border-b-dashed mt-4 m-2 d-flex justify-content-between">
                            <p className="fs-6 fw-bolder text-secondary " >
                              BRANCH
                            </p>
                            <p className="fw-bolder">{ele?.bank_branch}</p>

                          </div> */}
                          <div className=" border-b-dashed mt-4 m-2 d-flex justify-content-between">
                            <p className="fs-6 fw-bolder text-secondary" >ACCOUNT TYPE
                            </p>
                            <p className="fw-bolder">{ele?.account_type}</p>

                          </div>
                          <div className="col-lg-12 d-flex justify-content-end" onClick={() => handleEditClick(ele)}>
                            <span className="cursorPointer">
                              <svg
                                className="bi bi-pencil border-bottom border-dark"
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                viewBox="0 0 16 16"
                              >
                                <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" />
                              </svg>
                              <span className="mx-1">Edit</span>
                            </span>
                          </div>


                        </div>
                      )}

                    </div>
                  </div>
                </div>

              </div>
            </div>
          })
        }

        {loader == true &&
          <div className="text-center mt-5" >
            <button className="btn mt-5" style={{ backgroundColor: '#FFEDC2' }} type="button">
              <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>{" "}
              Loading...
            </button>
          </div>
        }


        {/* Update Profile Modal */}
        <Modal
          isOpen={actionModal?.show}
          onRequestClose={() => setActionModal({})}
          style={customActionModalStyles}
          contentLabel="Action Modal"
          ariaHideApp={false}
        >
          <div className="px-3 py-3">
            <div className="row">
              <span className="d-flex justify-content-end cursorPointer">
                <svg onClick={() => setActionModal({})} className="d-inline" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                  <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
                </svg>
              </span>

            </div>
            <div className="row">
              <h4 className="text-danger d-flex justify-content-center">Do you want to {actionModal?.type} franchise?</h4>
            </div>
            <div className="row my-5">
              <h5 className="d-flex justify-content-center">{actionModal?.data?.name}</h5>
            </div>
            <div className="form-group my-3 d-flex justify-content-center">
              <button disabled={actionModal?.loader}
                className="btn btn-dark me-3 mb-2 text-warning px-5"
              >
                {
                  actionModal?.loader
                    ?
                    <div className="spinner-border spinner-border-sm" role="status">
                      <span className="sr-only"></span>
                    </div>
                    :
                    "Submit"
                }
              </button>
              <button disabled={actionModal?.loader} className="btn btn-light me-3 mb-2 shadow-sm text-dark border px-5" onClick={() => setActionModal({})}>
                Cancel
              </button>
            </div>
          </div>
        </Modal>

        {/* Franchise Update Modal  */}
        <Modal
          isOpen={modal}
          onRequestClose={() => { setModal(false); setFranchiseData({}); setLoader(false) }}
          style={customModalStyles}
          contentLabel="Example Modal"
          ariaHideApp={false}
        >
          <div className="px-3 py-3">
            <div className="row">
              <span className="d-flex justify-content-end cursorPointer">
                <svg onClick={handleCloseModal} className="d-inline" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                  <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
                </svg>
              </span>
            </div>
            <div className="container  overflow-auto rounded bg-white row w-100 ">
              <h5 className="text-center" >Update Franchise Bank Details</h5>
              {/* <div className="row col-md-12 "> </div> */}
              <div className="row">
                <div className="col-md-1"></div>
                <div className="col-md-5">
                  <Form onSubmit={handleSubmitFranchise} >
                    <Row>
                      {/* <Form.Group className="mb-3" controlId="Owner Name">
                        <Form.Label>Owner Name</Form.Label>
                        <Form.Control
                          className="Textbar"
                          type="text"
                          placeholder="Type your name hear"
                          name='name'
                          value={franchiseData.name}
                          isInvalid={franchiseDataError?.name}
                          autoComplete='off'
                          onChange={handleFranchisechange} />
                        <span className="text-danger" >{franchiseDataError.name}</span>
                      </Form.Group> */}

                      {/* <Form.Group className="mb-3" controlId="Phone Number">
                        <Form.Label>Phone Number</Form.Label>
                        <Form.Control
                          className="Textbar"
                          type="number"
                          placeholder="type your Phone number hear"
                          name='number'
                          value={franchiseData.number}
                          onChange={handleFranchisechange}
                          isInvalid={franchiseDataError?.number}
                          autoComplete='off'
                          maxLength={10}
                          onInput={(e) => {
                            if (e.target.value.length > e.target.maxLength) {
                              e.target.value = e.target.value.slice(
                                0,
                                e.target.maxLength
                              )
                            }
                          }}
                        />
                        <span className="text-danger" >{franchiseDataError.number}</span>
                      </Form.Group> */}
                      {/* <Form.Group className="mb-3" controlId="Email ID">
                        <Form.Label>Email ID</Form.Label>
                        <Form.Control
                          className="Textbar"
                          type=""
                          placeholder="type your Email Id hear"
                          name='email'
                          value={franchiseData.email}
                          onChange={handleFranchisechange}
                          isInvalid={franchiseDataError?.email}
                          autoComplete='off'
                        />
                        <span className="text-danger" >{franchiseDataError.email}</span>
                      </Form.Group> */}
                      {/* <Form.Group className="mb-3" controlId="Society/Community">
                        <Form.Label>Society/Community</Form.Label>
                        <Form.Control
                          className="Textbar"
                          type="text"
                          placeholder="type your Society/Community hear"
                          name='society'
                          value={franchiseData.society}
                          onChange={handleFranchisechange}
                          isInvalid={franchiseDataError?.society}
                          autoComplete='off'
                        />
                        <span className="text-danger" >{franchiseDataError.society}</span>
                      </Form.Group> */}

                      {/* <Form.Group className="mb-3" controlId="Address 1">
                        <Form.Label>Address</Form.Label>
                        <Form.Control
                          className="Textbar"
                          type="text"
                          placeholder="Type your Address"
                          name='address1'
                          value={franchiseData.address1}
                          isInvalid={franchiseDataError?.address1}
                          autoComplete='off'
                          onChange={handleFranchisechange}
                        />
                        <span className="text-danger" >{franchiseDataError.address1}</span>

                      </Form.Group> */}
                    </Row>
                    <Row className="" >
                      {/* <div className="col-md-3"> */}
                      {/* <Form.Group className="mb-3" controlId="State">
                        <Form.Label>State</Form.Label>
                        <Form.Control
                          className="Textbar"
                          type="text"
                          placeholder="type your State hear"
                          name='state'
                          value={franchiseData.state}
                          onChange={handleFranchisechange}
                          isInvalid={franchiseDataError?.state}
                          autoComplete='off'
                        />
                        <span className="text-danger" >{franchiseDataError.state}</span>

                      </Form.Group> */}
                      {/* </div> */}
                      {/* <div className="col-md-3" style={{marginLeft:'150px'}}> */}
                      {/* <Form.Group className="mb-3" controlId="City">
                        <Form.Label className="">City</Form.Label>
                        <Form.Control
                          className="Textbar"
                          type="text"
                          placeholder="type your City hear"
                          name='city'
                          value={franchiseData.city}
                          onChange={handleFranchisechange}
                          isInvalid={franchiseDataError?.city}
                          autoComplete='off'
                        />
                        <span className="text-danger" >{franchiseDataError.city}</span>
                      </Form.Group> */}
                      {/* </div> */}

                      <Form.Group className="mb-3" controlId="bank_name">
                        <Form.Label className="">Bank Name</Form.Label>
                        <Form.Control
                          className="Textbar"
                          type="text"
                          placeholder="type your Bank Name"
                          name='bank_name'
                          value={franchiseData.bank_name}
                          onChange={handleFranchisechange}
                          isInvalid={franchiseDataError?.bank_name}
                          autoComplete='off'
                        />
                        <span className="text-danger" >{franchiseDataError.bank_name}</span>
                      </Form.Group>
                      {/* <Form.Group className="mb-3" controlId="bank_branch">
                        <Form.Label className="">Bank branch</Form.Label>
                        <Form.Control
                          className="Textbar"
                          type="text"
                          placeholder="type your Branch Name"
                          name='bank_branch'
                          value={franchiseData.bank_branch}
                          onChange={handleFranchisechange}
                          isInvalid={franchiseDataError?.bank_branch}
                          autoComplete='off'
                        />
                        <span className="text-danger" >{franchiseDataError.bank_branch}</span>
                      </Form.Group>  */}
                      <Form.Group className="mb-3" controlId="account_number">
                        <Form.Label className="">Account Number</Form.Label>
                        <Form.Control
                          className="Textbar"
                          type="text"
                          placeholder="type your Account Number"
                          name='account_number'
                          value={franchiseData.account_number}
                          onChange={handleFranchisechange}
                          isInvalid={franchiseDataError?.account_number}
                          autoComplete='off'
                          maxLength={14}
                        />
                        <span className="text-danger" >{franchiseDataError.account_number}</span>
                      </Form.Group>
                      <Form.Group className="mb-3" controlId="ifsc_code">
                        <Form.Label className="">Ifsc Code </Form.Label>
                        <Form.Control
                          className="Textbar"
                          type="text"
                          placeholder="type your Ifsc Code"
                          name='ifsc_code'
                          value={franchiseData.ifsc_code}
                          onChange={handleFranchisechange}
                          isInvalid={franchiseDataError?.ifsc_code}
                          autoComplete='off'
                          maxLength={11}
                        />
                        <span className="text-danger" >{franchiseDataError.account_type}</span>
                      </Form.Group>
                      <Form.Group className="mb-3" controlId="account_type">
                        <Form.Label className="">Account Type</Form.Label>
                        <Form.Select
                          className="Textbar"
                          name="account_type"
                          value={franchiseData.account_type}
                          onChange={handleFranchisechange}
                          isInvalid={franchiseDataError?.account_type}
                          autoComplete="off"
                        >
                          <option value="savings">Savings</option>
                          <option value="current">Current</option>
                        </Form.Select>
                        <span className="text-danger">{franchiseDataError.account_type}</span>
                      </Form.Group>

                    </Row>

                    <Row>
                      <div className="col-md-6">
                        {/* <Form.Group className="mb-3" controlId="Pin code">
                          <Form.Label>Pin code</Form.Label>
                          <Form.Control
                            className="smtext"
                            type="number"
                            placeholder="type your Pin code hear"
                            name='pincode'
                            value={franchiseData.pincode}
                            onChange={handleFranchisechange}
                            isInvalid={franchiseDataError?.pincode}
                            autoComplete='off'
                            maxLength={6}
                            onInput={(e) => {
                              if (e.target.value.length > e.target.maxLength) {
                                e.target.value = e.target.value.slice(
                                  0,
                                  e.target.maxLength
                                );
                              }
                            }}
                          />
                          <span className="text-danger" >{franchiseDataError.pincode}</span>
                        </Form.Group> */}
                        {/* <Form.Group className="mb-3" controlId="pan_card">
                        <Form.Label>Pan Card Number</Form.Label>
                        <Form.Control
                          className="Textbar"
                          type="text"
                          placeholder="type here Pan Card Number"
                          name='pan_number'
                          value={franchiseData.pan_number}
                          onChange={handleFranchisechange}
                          isInvalid={franchiseDataError?.pan_number}
                          autoComplete='off'
                        />
                        <span className="text-danger" >{franchiseDataError.pan_number}</span>
                      </Form.Group> */}
                        {/* <Form.Group className="mb-3" controlId="adhar_card">
                        <Form.Label>Adhar Card Number</Form.Label>
                        <Form.Control
                          className="Textbar"
                          type="text"
                          placeholder="type here Adhar Card Number"
                          name='adhar_number'
                          value={franchiseData.adhar_number}
                          onChange={handleFranchisechange}
                          isInvalid={franchiseDataError?.adhar_number}
                          autoComplete='off'
                        />
                        <span className="text-danger" >{franchiseDataError.adhar_number}</span>
                      </Form.Group> */}

                      </div>
                      <div></div>
                    </Row>

                    {
                      franchiseData.aadhar == '' ?
                        <Row>

                          {/* <Form.Group className="mb-3" controlId="Aadhar card" >
                            <Form.Label>Aadhar card</Form.Label>
                            <div className="form-group py-2 position-relative mb-3 g-0">
                              <input type="file"
                                name='aadhar'
                                value={franchiseData.aadhar}
                                onChange={handleFranchisechange}
                                className="opacity0 position-absolute w-75 placeholder-light form-control py-2 imgheight px-3" />
                              <div className="adhar-box text-center" >w
                                <div className="mt-5" >
                                  <img src={placeholder} />{" "}<span style={{ color: '#A3A3A3' }}>Upload your Aadhar card here...</span>
                                </div>
                              </div>
                            </div>
                            <span className="text-danger" >{franchiseDataError.aadhar}</span>
                          </Form.Group> */}
                        </Row>
                        :
                        <Row>
                          {/* <Form.Label>Aadhar Card</Form.Label>
                          <div className="adhar-box mb-3 g-0" style={{ marginLeft: "12px" }} onClick={() => handleReeUploadFile('aadhar')}>
                            <img src={aadharImage == '' ? franchiseData.aadhar : aadharImage} width='100%' height='100%' />
                          </div>
                          <input type="file" name='aadhar' onChange={handleFranchisechange} className="opacity0" id='aadhar' /> */}
                        </Row>

                    }
                    {
                      franchiseData.pan == '' ?
                        <Row>

                          <Form.Group className="mb-3" controlId="Pan Card">
                            <Form.Label>Pan Card</Form.Label>
                            <div className="form-group py-2 position-relative mb-3 g-0">
                              <input type="file"
                                name='pan'
                                value={franchiseData.pan}
                                onChange={handleFranchisechange}
                                className="opacity0 position-absolute w-75 placeholder-light form-control py-2 imgheight px-3" />
                              <div className="adhar-box text-center" >
                                <div className="mt-5" >
                                  <img src={placeholder} />{" "}<span style={{ color: '#A3A3A3' }}>Upload your Pan card here...</span>
                                </div>
                              </div>
                              <span className="text-danger" >{franchiseDataError.pan}</span>

                            </div>

                          </Form.Group>
                        </Row> :
                        <Row>
                          {/* <Form.Label>Pan Card</Form.Label>
                          <div className="adhar-box mb-3 g-0" style={{ marginLeft: "12px" }} onClick={() => handleReeUploadFile('pan')} >
                            <img src={panImage == '' ? franchiseData.pan : panImage} width='100%' height='100%' />
                          </div>
                          <input type="file" name='pan' onChange={handleFranchisechange} className="opacity0" id='pan' /> */}
                        </Row>
                    }

                    {/* <Form.Label>COD</Form.Label>
                    <div className="col-4">
                      <label className="switch">
                        <input className="switch-input" type="checkbox"
                          name='cod_status'
                          checked={franchiseData?.cod_status}
                          onChange={(e) => setFranchiseData({ ...franchiseData, cod_status: franchiseData?.cod_status == false ? true : false })}
                        />
                        <span className="switch-label"></span>
                        <span className="switch-handle"></span>
                      </label>
                    </div> */}
                    <Row>
                      {/* <Form.Group className="mb-3 Textbar" controlId="Reffered by">
                        <Form.Label>Reffered by</Form.Label>
                        <Form.Control
                          className="Textbar"
                          type="text"
                          disabled
                          placeholder="Reffered by"
                          name='referred_by'
                          value={franchiseData.referred_by}
                          onChange={handleFranchisechange}
                          isInvalid={franchiseDataError?.referred_by}
                          autoComplete='off'
                        />
                        <span className="text-danger" >{franchiseDataError.referred_by}</span>
                      </Form.Group> */}

                      <div className="row" id="row"></div>

                      <div className="mb-3">
                        <Button variant="primary" type="submit" size="lg" id="btnRegister">
                          {loader ? (
                            <Spinner
                              animation="border"
                              variant="light"
                              size="sm"
                            />
                          ) : (
                            <p className="login-text m-1">Update</p>
                          )}
                        </Button>
                      </div>
                    </Row>
                  </Form>
                </div>

                <div className="col-md-6">
                  <div className="row">
                    <div className="col-md-3"></div>
                    <div className="col-md-3"></div>
                    <div className="col-md-6">
                      {
                        franchiseData.profile == '' ?
                          <Row>
                            <Form.Group className="mb-3" controlId="Pan Card">
                              <div className="form-group py-2 position-relative mb-3">
                                <input type="file"
                                  name='profile'
                                  value={franchiseData.profile}
                                  onChange={handleFranchisechange}
                                  isInvalid={franchiseDataError?.profile}
                                  className="opacity0 position-absolute w-75 placeholder-light form-control py-2 imgheight px-3" />
                                <div className="profile-box text-center" >
                                  <div style={{ marginTop: "80px" }}>
                                    <img src={placeholder} />{" "}<span style={{ color: '#A3A3A3' }}>Upload Photo</span>
                                  </div>
                                </div>
                              </div>
                              <span className="text-danger" >{franchiseDataError.profile}</span>
                            </Form.Group>
                          </Row>
                          :
                          <Row>
                            <div className="profile-box mb-3 g-0" onClick={() => handleReeUploadFile('profile')}>
                              <img src={profileImage == '' ? franchiseData.profile : profileImage} width='100%' height='100%' />
                            </div>
                            <input type="file" name='profile' onChange={handleFranchisechange} className="opacity0" id='profile' />
                          </Row>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </>
    </MainTemplate>
  );
}

export default FranchiseProfile;
