import React, { useState } from "react";
import "./Inventory.css";
import MainTemplate from "../../../components/MainTemplate";
import Green from "../../../assets/images/CategoryDetail/green_xl.png"
import Bread from "../../../assets/images/CategoryPage/breads and cakes.png"
import Cleaner from "../../../assets/images/CategoryPage/cleaners.png"
import Dairy from "../../../assets/images/CategoryPage/dairy and eggs.png"
import Sweets from "../../../assets/images/CategoryPage/sweets.png"
import ReactPaginate from "react-paginate";

function Inventory() {

	const data = [
		{ name: "Royal Gala Apple" },
		{ name: "Organic Potatoes" },
		{ name: "Bok choi" },
		{ name: "Hide n Seek" },
		{ name: "Cadbury Oreo" },
		{ name: "Royal Gala Apple" },
	]

	const data2 = [


	]

	const [itemOffset, setItemOffset] = useState(0);
	const itemsPerPage = 10
	const endOffset = itemOffset + itemsPerPage;
	const pageCount = Math.ceil(data2.length / itemsPerPage);
	const currentItems = data2.slice(itemOffset, endOffset);

	const handlePageClick = (event) => {
		const newOffset = (event.selected * itemsPerPage) % data2.length;
		setItemOffset(newOffset);
	};

	return (
		<MainTemplate categoryName="Categories" categoryList={["Franchise", "Inventory", "Dashboard"]}>

			<div className="row mb-4 rounded p-2 me-2 bg-white">
				<div className="col-sm-6 my-3 col-md-2">
					<select className="t-12 form-select" aria-label="Default select example" >
						<option selected>Today</option>
						<option value="1">One</option>
						<option value="2">Two</option>
						<option value="3">Three</option>
					</select>
				</div>
			</div>

			<div className="row mb-4">
				<div className="col-sm-6 col-lg-3 p-2 pe-3 ps-0">
					<div className="bg-white rounde p-3">
						<p className="font-weight-bold">Total number of products</p>
						<h1 className="font-weight-bold">548 <span className="text-success t-16">+21</span></h1>
					</div>
				</div>
				<div className="col-sm-6 col-lg-3 p-2 pe-3">
					<div className="bg-white rounded p-3">
						<p className="font-weight-bold">Number of orders today</p>
						<h1 className="font-weight-bold">11100<span className="text-success t-16">+10</span></h1>
					</div>
				</div>
				<div className="col-sm-6 col-lg-3 p-2 pe-3">
					<div className="bg-white rounded p-3">
						<p className="font-weight-bold">Product expired</p>
						<h1 className="font-weight-bold">2<span className="text-danger t-16">+1</span></h1>
					</div>
				</div>
				<div className="col-sm-6 col-lg-3 p-2 pe-3">
					<div className="bg-white rounded p-3">
						<p className="font-weight-bold">Number of product returns</p>
						<h1 className="font-weight-bold">96<span className="text-success t-16">+10</span></h1>
					</div>
				</div>
			</div>

			<div className="row mb-3 p-0 t-14">
				<div className="col-lg-6 p-0 pe-3">
					<div className="pe-3 text-uppercase py-2 font-weight-bold">Low on stock</div>
					<div className="m-3 p-4 mx-0 mt-0 rounded bg-white">
						<div className="row">
							<div className="col-6 text-uppercase py-2 font-weight-bold border-b-dashed">Name</div>
							<div className="col-2 text-uppercase py-2 font-weight-bold border-b-dashed">Qty Left</div>
							<div className="col-4 text-uppercase py-2 font-weight-bold border-b-dashed d-flex justify-content-end">order More</div>
						</div>
						{
							data?.map(el => <div className="row">
								<div className="col-6 text-uppercase py-2 border-b-dashed">{el?.name}</div>
								<div className="col-2 text-uppercase py-2 border-b-dashed">{Math.ceil(Math.random() * 100)}</div>
								<div className="col-4 text-uppercase py-2 border-b-dashed d-flex justify-content-end"><span className="cursorPointer"><u>Order Now</u></span></div>
							</div>
							)
						}
						<div className="row pt-4"><span className=" d-flex justify-content-end cursorPointer"><u>View All</u></span></div>
					</div>
				</div>

				<div className="col-lg-6 p-0 pe-3">
					<div className="pe-3 text-uppercase py-2 font-weight-bold">Expiring soon</div>
					<div className="m-3 p-4 mx-0 mt-0 rounded bg-white">
						<div className="row">
							<div className="col-6 text-uppercase py-2 font-weight-bold border-b-dashed">Name</div>
							<div className="col-6 text-uppercase py-2 font-weight-bold border-b-dashed d-flex justify-content-end">Expiry</div>
						</div>
						{
							data?.map(el => <div className="row">
								<div className="col-6 text-uppercase py-2 border-b-dashed">{el?.name}</div>
								<div className="col-6 text-uppercase py-2 border-b-dashed d-flex justify-content-end">Expires in {Math.ceil(Math.random() * 10)} days</div>
							</div>
							)
						}
						<div className="row pt-4"><span className=" d-flex justify-content-end cursorPointer"><u>View All</u></span></div>
					</div>
				</div>
			</div>

			<div className="row mb-3 p-0 t-14">
				<div className="col-lg-6 p-0 pe-3">
					<div className="pe-3 text-uppercase py-2 font-weight-bold">Most Returned</div>
					<div className="m-3 p-4 mx-0 mt-0 rounded bg-white">
						<div className="row">
							<div className="col-6 text-uppercase py-2 font-weight-bold border-b-dashed">Name</div>
							<div className="col-6 text-uppercase py-2 font-weight-bold border-b-dashed d-flex justify-content-end">FREQ(per month)</div>
						</div>
						{
							data?.map(el => <div className="row">
								<div className="col-6 text-uppercase py-2 border-b-dashed">{el?.name}</div>
								<div className="col-6 text-uppercase py-2 border-b-dashed d-flex justify-content-end">{Math.ceil(Math.random() * 100)}</div>
							</div>
							)
						}
						<div className="row pt-4"><span className=" d-flex justify-content-end cursorPointer"><u>View All</u></span></div>
					</div>
				</div>

				<div className="col-lg-6 p-0 pe-3">
					<div className="pe-3 text-uppercase py-2 font-weight-bold">Most Ordered</div>
					<div className="m-3 p-4 mx-0 mt-0 rounded bg-white">
						<div className="row">
							<div className="col-6 text-uppercase py-2 font-weight-bold border-b-dashed">Name</div>
							<div className="col-6 text-uppercase py-2 font-weight-bold border-b-dashed d-flex justify-content-end">Expiry</div>
						</div>
						{
							data?.map(el => <div className="row">
								<div className="col-6 text-uppercase py-2 border-b-dashed">{el?.name}</div>
								<div className="col-6 text-uppercase py-2 border-b-dashed  d-flex justify-content-end">{Math.ceil(Math.random() * 10000)}</div>
							</div>
							)
						}
						<div className="row pt-4"><span className=" d-flex justify-content-end cursorPointer"><u>View All</u></span></div>
					</div>
				</div>
			</div>

			<div className="pe-3 text-uppercase py-2 font-weight-bold t-14">Overview</div>
			<div className="row rounded px-3 bg-white">

				{/* header */}
				<div className="row m-0 p-0">
					<div className="font-weight-bold t-12 text-uppercase my-3 col-md-1">
						Photo
					</div>
					<div className="font-weight-bold t-12 text-uppercase my-3 col-md-4">
						Name
					</div>
					<div className="font-weight-bold t-12 text-uppercase p-0 my-3 col-md-2">
						Qt
					</div>
					<div className="font-weight-bold t-12 text-uppercase p-0 my-3 col-md-2" >
						Expiry
					</div>
					<div className="font-weight-bold t-12 text-uppercase p-0 my-3 col-md-2" >
						Order
					</div>
					<div className="font-weight-bold t-12 text-uppercase p-0 my-3 col-md-1">

					</div>
				</div>

				{/* body */}
				<div className="m-0 p-0 pe-3 overflow-auto caterogyPageScroll">
					{
						currentItems?.map(el => <div className="row border rounded m-0 px-2  mb-2 d-flex align-items-center">
							<div className="t-12 text-uppercase my-3 col-md-1">
								<div className="categoryImageDiv shadow-sm  rounded p-1">
									{el?.product?.images && el.product?.images?.length > 0 ? (
										<img
											src={el?.product?.images[0].image}
											alt="loading"
											className="categoryImage h-100 rounded"
										/>
									) : (
										<img
											src={el?.product?.image}
											alt="loading"
											className="categoryImage h-100 rounded"
										/>
									)}
								</div>
							</div>
							<div className="t-12 text-uppercase my-3 col-md-4">
								{el?.category}
							</div>
							<div className="t-12 text-uppercase my-3 col-md-2">
								{el?.id} (1Kg)
							</div>
							<div className="t-12 text-uppercase my-3 col-md-2" >
								In {Math.ceil(Math.random() * 10)} days
							</div>
							<div className="t-12 text-uppercase my-3 col-md-2 ps-2">
								<span className="cursorPointer"><u>Order Now</u></span>
							</div>
							<div className="t-12 text-uppercase my-3 col-md-1">
								{/* <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-three-dots" viewBox="0 0 16 16">
									<path d="M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z"/>
								</svg> */}
							</div>
						</div>
						)
					}
				</div>
			</div>
			<div className="row m-2 d-flex justify-content-center align-items-center">
				<ReactPaginate
					breakLabel="..."
					nextLabel=">"
					onPageChange={handlePageClick}
					pageRangeDisplayed={3}
					pageCount={pageCount}
					previousLabel="<"
					renderOnZeroPageCount={null}
					pageClassName="page-item mb-0"
					subContainerClassName="pages "
					pageLinkClassName="page-link"
					previousClassName="page-item"
					previousLinkClassName="page-link"
					nextClassName="page-item"
					nextLinkClassName="page-link"
					breakClassName="page-item"
					breakLinkClassName="page-link"
					marginPagesDisplayed={2}
					containerClassName="pagination"
					activeClassName="active"
				/>
			</div>
		</MainTemplate>
	);
}

export default Inventory;