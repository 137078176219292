import React from "react";
import { Button, Form } from "react-bootstrap";
import MainTemplate from "../../components/MainTemplate";
import "../../pages/ProfileEdit/ProfileEdit.css";
import profileImg from "./../../assets/images/profile.png";
function ProfileEdit() {
  return (
    <MainTemplate
      categoryName="Profile"
      categoryList={["Franchise", "Profile"]}
    >
      <div className="row mb-4 rounded bg-white homepageModifierPadding mb-4">
        <div className="col-lg-7 mb-4">
          <div className="form-group py-2 pb-3 height-unicno">
            <label className="font-deatils">Unique Code</label>
            <input
              type="text"
              className="w-75 form-control py-2 px-3 mobilno-style"
              placeholder="#77667"
            />
          </div>
          <div className="form-group py-2 pb-3">
            <label className="font-deatils">Owner Name</label>
            <input
              type="text"
              className="w-75 form-control py-2 px-3 mainfont-styl"
              placeholder="Ram Nath Singh"
            />
          </div>
          <div className="form-group py-2 pb-3">
            <label className="font-deatils">Phone number</label>
            <div>
              <input
                type="number"
                className="w-75  form-control py-2 px-3 mobilno-style"
                placeholder="+91-987-675-6748"
              />
            </div>
          </div>
          <div className="form-group py-2 pb-3">
            <label className="font-deatils">Email ID</label>
            <input
              type="text"
              className="w-75 form-control py-2 px-3 mobilno-style"
              placeholder="ramnaths1@gmail.com"
            />
          </div>
          <div className="form-group py-2 pb-3">
            <label className="font-deatils">Society/Community</label>
            <input
              type="text"
              className="w-75 form-control py-2 px-3 small-style"
              placeholder="Aashray Ferms"
            />
          </div>
          <div className="form-group py-2 pb-3">
            <label className="font-deatils">Address line 1</label>
            <input
              type="text"
              className="w-75  form-control py-2 px-3 small-style"
              placeholder="#152 Ram Sang Market, 123 Main Street, 21st Cross, 3rd Block"
            />
          </div>
          <div className="form-group py-2 pb-3">
            <label className="font-deatils">Address line 2</label>
            <input
              type="text"
              className="w-75 form-control py-2 px-3 small-style"
              placeholder="Pathana Road, Opposite Rani mandir"
            />
          </div>
          <div className="row">
            <div className="col-md-6 form-group py-2 pb-3">
              <label className="font-deatils">State</label>
              <input
                type="text"
                className="w-75 form-control py-2 px-3 small-style"
                placeholder="Punjab"
              />
            </div>
            <div className="col-md-6 form-group py-2 pb-3 city-margin">
              <label className="font-deatils">City</label>
              <input
                type="text"
                className="w-75 form-control py-2 px-3 small-style"
                placeholder="Jalandhar"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 form-group py-2 pb-3">
              <label className="font-deatils">PIN code</label>
              <input
                type="text"
                className="w-75 form-control py-2 px-3 small-style"
                placeholder="560078"
              />
            </div>
            <div className="col-md-6"></div>
          </div>

          <div className="form-group py-2 pb-3">
            <label className="font-deatils">Aadhar Card</label>
            <br></br>
            <textarea
              className="Aadharredus small-style form-control"
              rows="8"
              cols="57"
              placeholder="Aadhar_Card.png uploaded"
            />
          </div>
          <div className="form-group py-2 pb-3">
            <label className="font-deatils">Pan Card</label>
            <br></br>
            <textarea
              className="Aadharredus small-style form-control"
              rows="8"
              cols="57"
              placeholder="Pan_card.jpg uploaded"
            />
          </div>
          <div className="form-group py-2 pb-3">
            <label className="font-deatils">Reffered by</label>
            <input
              type="text"
              className="w-75 form-control py-2 px-3 small-style"
              placeholder="Radha Kishan from Sobha villas"
            />
          </div>
          <div className="form-group py-2 pb-3">
            <button className="btn btn-dark text-warning px-5 btn-saves">
              Save
            </button>
          </div>
        </div>
        <div className="col-lg-1 mb-4"></div>
        <div className="col-lg-3 mt-5 mb-4">
          <img src={profileImg} />
        </div>
      </div>
   </MainTemplate>
  );
}

export default ProfileEdit;
